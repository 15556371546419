<template>
  <v-dialog v-model="dialog" activator="parent" width="350">
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="dialog = false" />

      <v-card-text class="pa-4 pb-0">
        <h3 class="font-weight-regular text-center">Add Joining</h3>
        <v-form ref="refForm">
          <v-row dense>
            <v-col cols="12">
              <VueDatePicker
                v-model="JobApplicationStore.joinedOn"
                :is-24="false"
                dark
                :clearable="false"
                :enable-time-picker="false"
                class="mt-5"
                teleport-center
                auto-apply
                month-name-format="long"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Comment"
                :rules="[requiredValidator, commentLengthValidator]"
                counter="30"
                v-model="JobApplicationStore.comment"
                hide-details="auto"
                color="#b2d250"
                variant="outlined"
                @input="
                  JobApplicationStore.comment = $event.target.value.replace(/\s+/g, ' ')
                "
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onSubmit()" variant="tonal" color="#74a942"> Submit </v-btn>
        <v-btn @click="dialog = false" variant="tonal" color="red"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="onJoining()"
    @update-model="confirmDialogStage = false"
    title="Joined"
    text="Are you sure you want to Joined?"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { getYYYYMMDDFormat } from "@/composables/DateTime";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { computed, inject, defineProps, ref } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { useClientAgreementStore } from "@/store/Client/ClientAgreementStore";
import { commentLengthValidator } from "@/composables/Validators";
const $cookies = inject("$cookies");
const userId = $cookies.get("user").id;
const props = defineProps(["dialog", "data"]);
const clientAgreementStore = useClientAgreementStore();
const JobApplicationStore = useJobApplicationStore();
const dialog = ref(false);
const confirmDialogStage = ref(false);
const refForm = ref();
const newDate = computed(() => {
  return getYYYYMMDDFormat(JobApplicationStore.joinedOn);
});
const onSubmit = () => {
  refForm.value?.validate().then(async (valid) => {
    if (valid.valid) {
      JobApplicationStore.jobApplicationId = props.data?.jobApplicationId;
      await clientAgreementStore.getAgreementTierTypes(props.data?.clientId);

      if (clientAgreementStore.tierType) {
        if (clientAgreementStore.tierType?.tierType === "Hybrid") {
          clientAgreementStore.selectedTierTypeForm = true;
          dialog.value = false;
        } else {
          confirmDialogStage.value = true;
        }
      }
    }
  });
};

const onJoining = async () => {
  let data = {
    joinedOn: newDate.value,
    comment: JobApplicationStore.comment,
    tierName: JobApplicationStore.tierName,
  };
  confirmDialogStage.value = false;
  dialog.value = false;
  await JobApplicationStore.joinPlacement(props.data?.jobApplicationId, data);
  await JobApplicationStore.getPlacements(userId);
};
</script>
