<template>
  <v-dialog v-model="dialog" activator="parent" width="350">
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="dialog = false" />
      <v-card-text class="pa-4 pb-0">
        <h3 class="text-center font-weight-regular">{{ props.title }}</h3>
        <v-form ref="refForm">
          <v-row dense>
            <v-col cols="12">
              <VueDatePicker
                v-model="dateTime"
                :is-24="false"
                :clearable="false"
                dark
                :enable-time-picker="false"
                teleport-center
                month-name-format="long"
                ><template #dp-input>
                  <CustomTextFieldComponent
                    type="text"
                    hide-details="auto"
                    readonly
                    :model-value="convertInterviewDate"
                    prepend-inner-icon="mdi-calendar"
                    :rules="[requiredValidator]"
                    label="Date"
                  >
                  </CustomTextFieldComponent>
                </template>
              </VueDatePicker>
            </v-col>
            <v-col cols="12">
              <VueDatePicker
                v-model="time"
                time-picker
                :is-24="false"
                :clearable="false"
                dark
                teleport-center
                month-name-format="long"
                ><template #dp-input>
                  <CustomTextFieldComponent
                    type="text"
                    hide-details="auto"
                    readonly
                    :model-value="time ? timeObject(time) : ''"
                    prepend-inner-icon="mdi-clock-outline"
                    :rules="[requiredValidator]"
                    label="Time"
                  >
                  </CustomTextFieldComponent>
                </template>
              </VueDatePicker>
            </v-col>
            <v-col cols="12" v-if="props.title === 'Reschedule Interview'">
              <v-file-input
                color="#b2d250"
                v-model="file"
                clearable
                hide-details="auto"
                label="File input"
                accept="image/png, image/jpeg, image/jpg"
                :rules="[requiredValidator]"
                placeholder="Select your file"
                variant="outlined"
                :show-size="1000"
                multiple
              >
              </v-file-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Comment"
                rows="3"
                color="#b2d250"
                variant="outlined"
                @input="comment = $event.target.value.replace(/\s+/g, ' ')"
                counter="30"
                v-model="comment"
                hide-details="auto"
                :rules="[requiredValidator, commentLengthValidator]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onSubmit()" variant="tonal" color="#74a942">
          Submit
        </v-btn>
        <v-btn @click="dialog = false" variant="tonal" color="red">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="rescheduleInterview()"
    @update-model="confirmDialogStage = false"
    :title="`${props.title}`"
    :text="`Are you sure you want to ${props.title}?`"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment-timezone";
import { computed, ref, defineEmits, defineProps } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { getYYYYMMDDFormat, timeObject } from "@/composables/DateTime";
import { commentLengthValidator } from "@/composables/Validators";
import { formatFileName } from "@/composables/Formatters";
const props = defineProps(["title"]);
const emit = defineEmits(["reschedule-intervew", "shortlisted"]);
const confirmDialogStage = ref(false);
const refForm = ref();
const file = ref(null);
const dateTime = ref();
const time = ref(null)
const convertInterviewDate = computed(() => {
  return getYYYYMMDDFormat(dateTime.value) === "Invalid date" ||
    getYYYYMMDDFormat(dateTime.value).includes("1970-01-01")
    ? ""
    : getYYYYMMDDFormat(dateTime.value);
});
const formattedInterviewDateAndTime = computed(() => {
  const date = getYYYYMMDDFormat(dateTime.value);
  const localDateString = moment(
    `${date} ${time.value.hours}:${time.value.minutes}`,
    "YYYY-MM-DD HH:mm"
  )
    .tz("Asia/Kolkata")
    .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  return moment(localDateString).format("YYYY-MM-DDTHH:mm:ss.SSS");
});
const comment = ref("");
const dialog = ref(false);
const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialogStage.value = true;
    }
  });
};
const rescheduleInterview = async () => {
  let rescheduleData = {
    dateTime: formattedInterviewDateAndTime.value,
    file: file.value ? formatFileName(file.value[0]) : null,
    comment: comment.value,
  };
  if (props.title === "Reschedule Interview") {
    emit("reschedule-intervew", rescheduleData);
  } else {
    emit("shortlisted", rescheduleData);
  }
  confirmDialogStage.value = false;
  dialog.value = false;
};
</script>
