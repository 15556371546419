<template>
  <div v-for="interview in props.interviews" :key="interview">
    <div v-if="interview.level === props.level">
      <v-card-text>
        <div class="d-flex justify-center">
          <div>
            <h2 class="font-weight-regular text-center">
              The interview has been completed!
            </h2>
            <h3 class="font-weight-regular text-lighten-grey text-center ma-3">
              {{
                `Please provide an update on the status of ${props.level}
              interview. Thank you!`
              }}
            </h3>
            <div class="d-flex justify-center mt-4">
              <v-btn
                color="green"
                variant="tonal"
                class="me-3"
                @click="confirmPassInterview(interview.id)"
                >Pass
              </v-btn>
              <v-btn
                color="red"
                variant="tonal"
                @click="selectedInterviewId = interview.id"
                >Fail
                <CommentDialog
                  title="Fail Interview"
                  @fail-interview="failInterview"
              /></v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </div>
  </div>

  <ConfirmationDialoge
    v-model="passInterviewDialog"
    @action-yes="passInterview()"
    @update-model="passInterviewDialog = false"
    title="Pass Interview"
    text="Are you sure you want to Pass Interview?"
  />
</template>
  
  <script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CommentDialog from "@/components/JobAplication/dialogs/CommentDialog.vue";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { ref } from "vue";
import { defineProps } from "vue";
import { useInterviewStore } from "@/store/JobApplications/InterviewStore";

const jobApplicationStore = useJobApplicationStore();
const interviewStore = useInterviewStore();
const props = defineProps(["level", "interviews"]);
const selectedInterviewId = ref("");
const passInterviewDialog = ref(false);
const confirmPassInterview = (interviewId) => {
  selectedInterviewId.value = interviewId;
  passInterviewDialog.value = true;
};
const passInterview = async () => {
  const comment = "Interview has been Passed";
  passInterviewDialog.value = false;
  await interviewStore.passInterview(selectedInterviewId.value, { comment });
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
const failInterview = async (comment) => {
  await interviewStore.failInterview(selectedInterviewId.value, comment);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
</script>