<template>
  <v-dialog v-model="jobApplicationStore.updateJobApplicatioDialog" max-width="800">
    <v-card color="#303030">
      <DialogCloseBtn
        @close-dialog="jobApplicationStore.updateJobApplicatioDialog = false"
      />
      <div
        v-if="isLoading"
        class="mx-auto text-center text-white text-h5"
        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
      >
        <v-progress-circular
          indeterminate
          color="#b2d250"
          size="large"
          class="text-center"
        ></v-progress-circular>
        <h4 class="font-weight-regular mt-3 text-center">Please Wait...</h4>
      </div>
      <v-card-text>
        <h3 class="font-weight-regular text-center mb-4">Update Job Application</h3>
        <v-form ref="refForm">
          <v-row dense>
            <v-col cols="12" lg="6" md="6">
              <CustomSelectComponent
                v-model="source"
                :items="[
                  'Naukri',
                  'Shine',
                  'Monster',
                  'Linkedin',
                  'Social Media',
                  'Mass Mailing',
                  'Head Hunting',
                  'Referral',
                  'Walk In',
                  'Other',
                ]"
                :rules="[requiredValidator]"
                label="Sourced From"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6">
              <CustomAutoComplete
                v-model="profession"
                variant="outlined"
                :rules="[requiredValidator]"
                :items="corporateProfessions"
                label="Profession"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6">
              <CustomTextFieldComponent
                v-model="currentLocation"
                label="Current Location"
                :rules="[requiredValidator, validateString]"
                @input="currentLocation = $event.target.value.replace(/\s+/g, ' ')"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              md="6"
              v-if="
                jobApplication?.job?.locations
                  ? jobApplication?.job?.locations[0] === null
                  : ''
              "
            >
              <CustomTextFieldComponent
                v-model="preferredLocation"
                :rules="[requiredValidator, validateString]"
                label="Preferred Location"
              />
            </v-col>

            <v-col cols="12" lg="6" md="6" v-else>
              <CustomSelectComponent
                v-model="preferredLocation"
                :rules="[requiredValidator]"
                label="Preferred Location"
                @input="preferredLocation = $event.target.value.replace(/\s+/g, ' ')"
                :items="jobApplication?.job?.locations"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <CustomTextFieldComponent
                v-model="currentDesignation"
                label="Current Designation"
                :rules="[requiredValidator]"
                @input="currentDesignation = $event.target.value.replace(/\s+/g, ' ')"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <CustomTextFieldComponent
                v-model="currentCompany"
                label="Current Company"
                :rules="[requiredValidator]"
                @input="currentCompany = $event.target.value.replace(/\s+/g, ' ')"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <CustomTextFieldComponent
                v-model.number="totalExperience"
                type="text"
                label="Total Experience"
                :rules="[requiredValidator]"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <CustomTextFieldComponent
                v-model.number="relevantExperience"
                type="text"
                label="Relevant Experience"
                :rules="[requiredValidator]"
              />
            </v-col>

            <v-col cols="12" lg="6">
              <CustomTextFieldComponent
                v-model="currentSalary"
                type="text"
                inputmode="numeric"
                @input="$event.target.value = $event.target.value.slice(0, 10)"
                v-maska:[vMaskOptions]
                :maxlength="10"
                :rules="[lengthOfSsalary, requiredValidator]"
                label="Current Salary"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <CustomTextFieldComponent
                v-model="expectedSalary"
                type="text"
                inputmode="numeric"
                @input="$event.target.value = $event.target.value.slice(0, 10)"
                v-maska:[vMaskOptions]
                :maxlength="10"
                :rules="[lengthOfSsalary, requiredValidator]"
                label="Expected Salary"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <CustomSelectComponent
                v-model.number="noticePeriodStatus"
                type="text"
                :items="noticePeriodStatuses"
                :rules="[requiredValidator]"
                label="Notice Period Status"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <v-checkbox
                label="Immediate Joiner"
                color="#b2d250"
                v-model="isImmediateJoiner"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" lg="4" sm="6" v-if="noticePeriodStatus === 'Immediate'">
              <v-menu v-model="menu1" :close-on-content-click="false" class="">
                <template v-slot:activator="{ props }">
                  <CustomTextFieldComponent
                    v-model="convertExpectedJoiningDate"
                    label="Expected Joining Date"
                    append-icon="event"
                    class="w-100"
                    clearable
                    hide-details="auto"
                    prepend-inner-icon="mdi-calendar-month-outline"
                    v-bind="props"
                  />
                </template>
                <DatePicker
                  v-model="expectedJoiningDate"
                  borderless
                  @dayclick="menu1 = false"
                  mode="date"
                  class="bg-grey-darken-4 rounded-xl w-100"
                  title-position="left"
                  color="green"
                  is-dark="{ selector: ':root', darkClass: 'dark' }"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              sm="6"
              class="dateInput"
              v-if="noticePeriodStatus === 'Serving'"
            >
              <v-menu v-model="menu" :close-on-content-click="false" class="">
                <template v-slot:activator="{ props }">
                  <CustomTextFieldComponent
                    v-model="convertLastWorkingDate"
                    label="Last Working Date"
                    append-icon="event"
                    hide-details="auto"
                    :rules="[requiredValidator]"
                    :disabled="status == 2"
                    readonly
                    prepend-inner-icon="mdi-calendar-month-outline"
                    v-bind="props"
                  />
                </template>
                <DatePicker
                  v-model="lastWorkingDate"
                  borderless
                  @dayclick="menu = false"
                  mode="month"
                  class="bg-grey-darken-4 rounded-xl w-100"
                  title-position="left"
                  color="green"
                  is-dark="{ selector: ':root', darkClass: 'dark' }"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" lg="4" v-if="noticePeriodStatus !== 'Immediate'">
              <CustomTextFieldComponent
                v-model.number="noticePeriodDays"
                type="text"
                :rules="[requiredValidator]"
                hide-details="auto"
                label="Notice Period Days"
              />
            </v-col>
          </v-row>
          <v-row no-gutters
            ><v-col cols="12" lg="5">
              <v-checkbox
                label="Is Notice Period Negotiable ?"
                color="#b2d250"
                hide-details="auto"
                v-model="isNegotiable"
              ></v-checkbox> </v-col
          ></v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onSubmit()" variant="tonal" color="#74a942"> Update </v-btn>
        <v-btn @click="dialog = false" variant="tonal" color="red"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmationDialoge"
    @action-yes="onUpdate()"
    @update-model="confirmationDialoge = false"
    title="Update Job Application"
    text="Are you sure you want to update Job Application?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import CustomSelectComponent from "@/components/SharedComponent/components/CustomSelectComponent.vue";
import CustomAutoComplete from "@/components/SharedComponent/components/CustomAutoComplete.vue";
import { computed, ref, watch } from "vue";
import { vMaska } from "maska";
import { DatePicker } from "v-calendar";
import { requiredValidator } from "@/composables/Validators";
import { validateString } from "@/composables/Validators";
import { getDDMMYYYYFormat, getYYYYMMDDFormat } from "@/composables/DateTime";
import { corporateProfessions } from "@/composables/CandidateProfession";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import api from "@/services/api";
const jobApplicationStore = useJobApplicationStore();
const noticePeriodStatuses = ["Immediate", "Serving", "Not Serving"];
const refForm = ref();
const confirmationDialoge = ref(false);
const menu = ref(false);
const menu1 = ref(false);
const isLoading = ref(false);
const jobApplication = ref();
const source = ref();
const profession = ref();
const relevantExperience = ref();
const totalExperience = ref();
const currentCompany = ref();
const currentDesignation = ref();
const currentSalary = ref();
const expectedSalary = ref();
const currentLocation = ref();
const preferredLocation = ref();
const noticePeriodStatus = ref();
const isImmediateJoiner = ref();
const noticePeriodDays = ref();
const isNegotiable = ref();
const lastWorkingDate = ref();
const expectedJoiningDate = ref();

const convertLastWorkingDate = computed(() => {
  return getDDMMYYYYFormat(lastWorkingDate.value) === "Invalid date" ||
    getDDMMYYYYFormat(lastWorkingDate.value) === "01/01/1970"
    ? ""
    : getDDMMYYYYFormat(lastWorkingDate.value);
});
const convertExpectedJoiningDate = computed(() => {
  return getDDMMYYYYFormat(expectedJoiningDate.value) === "Invalid date" ||
    getDDMMYYYYFormat(expectedJoiningDate.value) === "01/01/1970"
    ? ""
    : getDDMMYYYYFormat(expectedJoiningDate.value);
});
const vMaskOptions = {
  mask: "99,99,99#",
  tokens: {
    9: { pattern: /[0-9]/, repeated: true },
  },
  reversed: true,
};

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmationDialoge.value = true;
    }
  });
};
const onUpdate = () => {
  let data = {
    source: source.value,
    profession: profession.value,
    relevantExperience: relevantExperience.value,
    totalExperience: totalExperience.value,
    currentCompany: currentCompany.value,
    currentDesignation: currentDesignation.value,
    currentSalary: currentSalary.value
      ? Number(currentSalary.value?.split(",").join(""))
      : 0,
    expectedSalary: expectedSalary.value
      ? Number(expectedSalary.value?.split(",").join(""))
      : 0,
    currentLocation: currentLocation.value,
    preferredLocation: preferredLocation.value,
    noticePeriodStatus: noticePeriodStatus.value,
    isImmediateJoiner: isImmediateJoiner.value,
    noticePeriodDays: noticePeriodDays.value,
    isNegotiable: isNegotiable.value,
    lastWorkingDate:
      getYYYYMMDDFormat(lastWorkingDate.value) === "Invalid date"
        ? ""
        : getYYYYMMDDFormat(lastWorkingDate.value),
    expectedJoiningDate:
      getYYYYMMDDFormat(expectedJoiningDate.value) === "Invalid date"
        ? ""
        : getYYYYMMDDFormat(expectedJoiningDate.value),
  };
  confirmationDialoge.value = false;
  jobApplicationStore.updateJobApplicatioDialog = false;
  jobApplicationStore.updateJobApplicationDetails(data);
};
const getJobApplication = async () => {
  isLoading.value = true;
  await api
    .get(`/job-applications/${jobApplicationStore.jobApplication.id}/details`)
    .then((res) => {
      setJobApplicationDetails(res.data);
      jobApplication.value = res.data;
      isLoading.value = false;
    })
    .catch((err) => {
      isLoading.value = false;
      return err;
    });
};

const setJobApplicationDetails = (res) => {
  source.value = res.source;
  profession.value = res.profession;
  relevantExperience.value = res.relevantExperience;
  totalExperience.value = res.totalExperience;
  currentCompany.value = res.currentCompany;
  currentDesignation.value = res.currentDesignation;
  currentSalary.value = res.currentSalary;
  expectedSalary.value = res.expectedSalary;
  currentLocation.value = res.currentLocation;
  preferredLocation.value = res.preferredLocation;
  noticePeriodStatus.value = res.noticePeriodStatus;
  isImmediateJoiner.value = res.immediateJoiner;
  noticePeriodDays.value = res.noticePeriodDays;
  isNegotiable.value = res.negotiable;
  lastWorkingDate.value = res.lastWorkingDate;
  expectedJoiningDate.value = res.expectedJoiningDate;
};
watch(
  () => jobApplicationStore.updateJobApplicatioDialog,
  async () => {
    if (jobApplicationStore.updateJobApplicatioDialog) {
      await getJobApplication();
    }
  }
);
</script>
