<template>
  <v-dialog
    elevation="5"
    max-width="400"
    class="scrollable-content"
    transition="slide-y-reverse-transition"
    v-model="clientStore.addClientDialog"
  >
    <v-card
      transition="scroll-y-reverse-transition"
      theme="dark"
      rounded="lg"
      max-height="900"
      color="#303030"
    >
      <v-card-text>
        <!-- 👉 Title -->
        <h3 class="font-weight-regular text-center mb-4">Add Client</h3>
        <DialogCloseBtn @close-dialog="closeForm()" />

        <!--  Form -->
        <v-form ref="refForm">
          <v-row dense>
            <!--   name -->
            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="clientName"
                :rules="[requiredValidator]"
                @input="clientName = $event.target.value.replace(/\s+/g, ' ')"
                label="Name"
              />
            </v-col>

            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="legalName"
                @input="legalName = $event.target.value.replace(/\s+/g, ' ')"
                :rules="[requiredValidator]"
                label="Legal Name"
              />
            </v-col>
            <!-- 👉 Website -->

            <v-col cols="12" class="pt-0">
              <CustomTextFieldComponent
                v-model="website"
                label="Website"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center gap-3">
        <v-btn variant="tonal" color="#74a942" @click="onSubmit()"
          ><v-progress-circular
            indeterminate
            color="grey-darken-4"
            v-if="clientStore.isLoading === true"
          ></v-progress-circular>
          <p v-else>Submit</p></v-btn
        >
        <v-btn variant="tonal" color="red" @click="closeForm()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <ConfirmationDialoge
    v-model="confirmationDialog"
    @action-yes="onConfirm()"
    @update-model="confirmationDialog = false"
    title="Add Client"
    text="Want to add Client ?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import { ref } from "vue";
import { useClientStore } from "@/store/Client/ClientStore";
import { requiredValidator } from "@/composables/Validators";

const clientStore = useClientStore();
const confirmationDialog = ref(false);
const refForm = ref();
const clientName = ref("");
const legalName = ref("");
const website = ref("");

const closeForm = () => {
  clientName.value = "";
  legalName.value = "";
  website.value = "";
  clientStore.toggleAddClientDialog(false);
};

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmationDialog.value = true;
    }
  });
};
const onConfirm = () => {
  let client = {
    name: clientName.value,
    legalName: legalName.value,
    website: website.value,
  };
  confirmationDialog.value = false;
  clientStore.toggleAddClientDialog(false);
  clientStore.addClient(client);
};
</script>

<style scoped>
.v-input {
  display: block !important;
}
</style>
