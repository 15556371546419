import api from "@/services/api";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import axios from "axios";
import VueCookies from "vue-cookies";
import { useJobApplicationsStore } from "./JobApplicationsStore";
import { useTeamStore } from "../Team/TeamStore";

export const useJobApplicationStore = defineStore("jobApplicationStore", {
  state: () => ({
    userId: VueCookies.get("user")?.id,
    isLoading: false,
    updateJobApplicatioDialog: false,
    confirmUpdateRsume: false,
    viewOrUpdateRsumeDialog: false,
    viewOrUpdateAcknowledgmentDialog: false,
    confirmUpdateAcknowledgment: false,
    jobApplicationsStore: useJobApplicationsStore(),
    teamStore: useTeamStore(),
    viewResume: false,
    jobApplication: {},
    selected: [],
    offers: [],
    selectedOffer: null,
    placements: [],
    selectedCard: "awaited",
    selectedApplications: [],
    rollBacks: [],
    recruiters: "",
    joiningStatus: "",
    search: "",
    joinedOn: new Date(),
    comment: "",
    tierName: null,
    jobApplicationId: null,
  }),
  actions: {
    // Business Logic
    updateCV() {
      if (this.jobApplication.recruiter.id === this.userId) {
        this.confirmUpdateRsume = true;
      }
    },
    viewCV() {
      const url = this.jobApplication.resumeUrl
        ? this.jobApplication.resumeUrl
        : null;
      const fileName = url.substring(url.lastIndexOf("/") + 1);
      const fileExtension = fileName.split(".").pop();
      if (fileExtension === "pdf") {
        this.showResumePDf(url, "application/pdf");
      } else if (fileExtension === "docx") {
        this.showResumeDoc(
          url,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      } else if (fileExtension === "doc") {
        this.showResumeDoc(url, "application/msword");
      }
    },
    viewOrUpdateResumeDialog() {
      if (this.jobApplication.recruiter.id === this.userId) {
        this.viewOrUpdateRsumeDialog = true;
      } else {
        this.viewOrUpdateResume();
      }
    },
    viewOrUpdateResume() {
      if (!this.jobApplication.resumeUpdated) {
        toast.error("Resume not Updated", {
          autoClose: 1500,
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          transition: toast.TRANSITIONS.ZOOM,
        });
      } else {
        const url = this.jobApplication.resumeUrl
          ? this.jobApplication.resumeUrl
          : null;
        const fileName = url.substring(url.lastIndexOf("/") + 1);
        const fileExtension = fileName.split(".").pop();
        if (fileExtension === "pdf") {
          this.showResumePDf(url, "application/pdf");
        } else if (fileExtension === "docx") {
          this.showResumeDoc(
            url,
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          );
        } else if (fileExtension === "doc") {
          this.showResumeDoc(url, "application/msword");
        }
      }
    },
    updateAckngm() {
      if (this.jobApplication.recruiter.id === this.userId) {
        this.confirmUpdateAcknowledgment = true;
      }
    },
    viewAcknowledgment() {
      const url = this.jobApplication.acknowledgementUrl
        ? this.jobApplication.acknowledgementUrl
        : null;

      this.showAcknowledge(url);
    },
    viewOrUpdateAcknowledgementDialog() {
      if (this.jobApplication.recruiter.id === this.userId) {
        this.viewOrUpdateAcknowledgmentDialog = true;
      } else {
        this.viewOrUpdateAcknowledge();
      }
    },
    viewOrUpdateAcknowledge() {
      if (!this.jobApplication.acknowledged) {
        toast.error("Acknowledgement not Uploaded", {
          autoClose: 1500,
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          transition: toast.TRANSITIONS.ZOOM,
        });
      } else {
        const url = this.jobApplication.acknowledgementUrl
          ? this.jobApplication.acknowledgementUrl
          : null;

        this.showAcknowledge(url);
      }
    },
    showApprovalPendingBluerScreen() {
      const { senior, approved, status, allowedUsers } = this.jobApplication;
      const isNotAllowed = !allowedUsers?.includes(this.userId);
      const isSubmitted = status?.toLowerCase() === "submitted";
      const isNotSenior = senior.id !== this.userId;
      return (!approved && isSubmitted) || (isNotSenior ? isNotAllowed : false);
    },
    showMessageOnBlurScreen() {
      const { allowedUsers, approved, status } = this.jobApplication;
      const isNotAllowed = !allowedUsers?.includes(this.userId);
      const isSubmitted = status?.toLowerCase() === "submitted" && !approved;
      if (isNotAllowed)
        return "You're Not Allowed to Update The Status of the Application";
      if (isSubmitted) return "Approval Pending";
    },
    showApprovePendingButtons() {
      const { allowedUsers, approved, status, senior, recruiter } =
        this.jobApplication;
      const isSubmitted = status?.toLowerCase() === "submitted";
      const isAllowed = allowedUsers?.includes(this.userId);
      const isSenior = senior.id === this.userId;
      const isRecruiter = recruiter.id === this.userId;
      return (
        !approved &&
        isSubmitted &&
        (isRecruiter ? false : isAllowed || isSenior)
      );
    },
    showInterviewApproveButtons() {
      const { allowedUsers, senior, recruiter } = this.jobApplication;
      const isAllowed = allowedUsers?.includes(this.userId);
      const isSenior = senior.id === this.userId;
      const isRecruiter = recruiter.id === this.userId;
      return isRecruiter && isSenior
        ? true
        : isRecruiter
        ? false
        : isAllowed || isSenior;
    },
    isApproveButtonDisabled() {
      const { resumeUpdated, acknowledged } = this.jobApplication;
      return !resumeUpdated || !acknowledged;
    },
    showCurrentStatusScreen(status) {
      return this.jobApplication.currentHiringStatus === status;
    },
    showApplicationStatusScreen() {
      const { status } = this.jobApplication;
      const lowerCaseStatus = status?.toLowerCase();
      const rejectedStatus = lowerCaseStatus.includes("rejected");
      const withdrawnstatus = lowerCaseStatus.includes("withdrawn");
      const holdStatus = lowerCaseStatus.includes("hold");
      return rejectedStatus || withdrawnstatus || holdStatus;
    },
    showDropBtn() {
      const { approved, status, recruiter } = this.jobApplication;
      const isSubmitted = status?.toLocaleLowerCase() === "submitted";
      const isRecruiter = recruiter.id === this.userId;
      return !approved && isSubmitted && isRecruiter;
    },
    showCommonStatusBtn() {
      const { status } = this.jobApplication;
      const inProgress = status === "In Progress";
      const approved = status === "Approved";
      return inProgress || approved ? false : true;
    },
    refreshOffers(userId) {
      this.getOffers(userId);
    },
    refreshPlacements(userId) {
      this.getPlacements(userId);
    },
    //Endpoints
    async showResumePDf(url, contextType) {
      await axios
        .get(url, {
          headers: {
            Accept: "*/*",
          },
          responseType: "blob",
        })
        .then((response) => {
          const file = new Blob([response.data], { type: contextType });
          const pdfURL = URL.createObjectURL(file);
          window.open(pdfURL, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async showResumeDoc(url) {
      await axios
        .get(url, {
          headers: {
            Accept: "*/*",
          },

          responseType: "blob",
        })
        .then((response) => {
          const file = new Blob([response.data], {
            type: "application/msword",
          });

          const pdfURL = URL.createObjectURL(file);
          window.open(pdfURL, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async showAcknowledge(url) {
      await axios
        .get(url, {
          headers: {
            Accept: "*/*",
          },
          responseType: "blob",
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "image/jpg" });
          const pdfURL = URL.createObjectURL(file);
          window.open(pdfURL, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getJobApplication(jobApplicationId) {
      this.isLoading = true;
      await api
        .get(`/job-applications/${jobApplicationId}/details`)
        .then((res) => {
          this.jobApplication = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getJobApplicationDetails(jobApplicationId) {
      this.isLoading = true;
      await api
        .get(`/job-applications/${jobApplicationId}/details`)
        .then((res) => {
          this.jobApplication = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateJobApplicationDate(data) {
      (this.isLoading = true),
        await api
          .put(`/job-applications/${this.jobApplication.id}/update-date`, data)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data, {
                autoClose: 1500,
                theme: "dark",
                position: toast.POSITION.TOP_CENTER,
                transition: toast.TRANSITIONS.ZOOM,
              });
            }

            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async createJobApplicationClosure(data) {
      this.isLoading = true;
      await api
        .post(
          `/job-applications/${this.jobApplication.id}/create-closure`,
          data
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }

          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateJobApplicationDetails(data) {
      (this.isLoading = true),
        await api
          .put(`/job-applications/${this.jobApplication.id}`, data)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data, {
                autoClose: 1500,
                theme: "dark",
                position: toast.POSITION.TOP_CENTER,
                transition: toast.TRANSITIONS.ZOOM,
              });
            }

            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async updateResume(data) {
      (this.isLoading = true),
        await api
          .put(`/job-applications/${this.jobApplication.id}/resume`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async updateAcknowledgement(data) {
      (this.isLoading = true),
        await api
          .put(
            `/job-applications/${this.jobApplication.id}/acknowledgment`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async updateInterviewAcknowledgement(selectedInterviewId, data) {
      (this.isLoading = true),
        await api
          .put(
            `/job-applications/${this.jobApplication.id}/interview/${selectedInterviewId}/acknowledge`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    // process
    async commonJobApplicationStatus(data) {
      (this.isLoading = true),
        await api
          .put(
            `/job-applications/${this.jobApplication.id}/change-status`,
            data
          )
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },

    async dropJobApplication(data) {
      (this.isLoading = true),
        await api
          .put(`/job-applications/${this.jobApplication.id}/withdraw`, data)
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },

    async sharedWithClientJobApplication(jobApplicationId, data) {
      (this.isLoading = true),
        await api
          .put(`/job-applications/${jobApplicationId}/shared-with-client`, data)
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async shortlistedJobApplication(jobApplicationId, data) {
      (this.isLoading = true),
        await api
          .put(`/job-applications/${jobApplicationId}/shortlist`, data)
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async screeningRejectJobApplication(jobApplicationId, data) {
      (this.isLoading = true),
        await api
          .put(`/job-applications/${jobApplicationId}/screening-reject`, data)
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },

    // selection

    async selectedApplication(data) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${this.jobApplication.id}/select`, data)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getSelectedApplications(userId) {
      (this.isLoading = true),
        await api
          .get(`/users/${userId}/job-applications/selected`)
          .then((res) => {
            this.selectedApplications = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    //offer
    async addOffer(offerData) {
      this.isLoading = true;
      await api
        .post(`/job-applications/${this.jobApplication.id}/offers`, offerData)
        .then((res) => {
          this.offered.push(res.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getOffers(userId) {
      (this.isLoading = true),
        await api
          .get(`/users/${userId}/offers/status/${this.selectedOffer}`)
          .then((res) => {
            this.offers = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },

    async acceptOffer(data) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${this.jobApplication.id}/offers/accept`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async rejectOffer(data) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${this.jobApplication.id}/offers/reject`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateOffer(data) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${this.jobApplication.id}/offers`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async negotiationStarted(id) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${id}/offers/negotiation-started`)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async negotiationCompleted(id) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${id}/offers/negotiation-completed`)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    //joinings
    async addPlacement(data) {
      this.isLoading = true;
      await api
        .post(`/job-applications/${this.jobApplication.id}/placements`, data)
        .then((res) => {
          this.placements.push(res.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getPlacements(userId) {
      (this.isLoading = true),
        await api
          .get(`/users/${userId}/placements`)
          .then((res) => {
            this.placements = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async joinPlacement(id, data) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${id}/placements/join`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async withdrawPlacement(jobApplicationId, data) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${jobApplicationId}/placements/withdraw`, {
          reason: data,
        })
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async rejectPlacement(jobApplicationId, data) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${jobApplicationId}/placements/reject`, {
          reason: data,
        })
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async holdPlacement(jobApplicationId, data) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${jobApplicationId}/placements/hold`, {
          reason: data,
        })
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    //folllow up
    async rescheduleFolloUp(
      slectedJobApplicationId,
      placementId,
      followupId,
      data
    ) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${slectedJobApplicationId}/placements/${placementId}/followup/${followupId}/rescheduled`,
          data
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async doneFollowup(slectedJobApplicationId, placementId, followupId, data) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${slectedJobApplicationId}/placements/${placementId}/followup/${followupId}/done`,
          {
            remarks: data,
          }
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    //rollback
    async requestRollback(data) {
      this.isLoading = true;
      await api
        .post(`/job-applications/${this.jobApplication.id}/rollbacks`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getRollbacks() {
      this.isLoading = true;
      await api
        .get(`/job-applications/rollbacks`, {
          params: {
            page: 0,
            size: 300,
            status: "All",
            query: "All",
          },
        })
        .then((res) => {
          this.rollBacks = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async approveRollbackReq(jobApplicationId, rollbackId) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${jobApplicationId}/rollbacks/${rollbackId}/perform`
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async rejectRollbackReq(jobApplicationId, rollbackId, data) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${jobApplicationId}/rollbacks/${rollbackId}/reject`,
          data
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async changejobApplicationsStatus(value) {
      this.isLoading = true;
      await api
        .put(`/job-applications/${this.jobApplication.id}/activate`, {
          status: value,
        })
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
  },
  getters: {
    submittedCount() {
      const submitted = this.jobApplications?.filter(
        (application) => application.status === "SUBMITTED"
      );
      return submitted.length;
    },
    approvedCount() {
      const approvedApplications = this.jobApplications?.filter(
        (application) => application.status === "APPROVED"
      );
      return approvedApplications.length;
    },
    inProgressCount() {
      const approvedApplications = this.jobApplications?.filter(
        (application) => application.status === "IN-PROGRESS"
      );
      return approvedApplications.length;
    },
    OnHoldCount() {
      const approvedApplications = this.jobApplications?.filter(
        (application) => application.status === "On Hold"
      );
      return approvedApplications.length;
    },

    getAllClient() {
      const set = new Set();
      return this.jobApplications.map((application) =>
        set.add(application.job.client.legalName)
      );
    },
    getAllJobs() {
      if (this.clientName) {
        const filteredJobs = this.jobApplications
          .filter(
            (application) =>
              application.job.client.legalName === this.clientName
          )
          .map((application) => application.job.title);

        return [...new Set(filteredJobs)];
      } else {
        const jobs = this.jobApplications.map(
          (application) => application.job.title
        );
        return [...new Set(jobs)];
      }
    },
    getForattedOffers() {
      const search = this.selectedOffer?.toLocaleLowerCase();
      if (search) {
        const data = this.offers.filter(
          (offer) => offer.status?.toLocaleLowerCase() === search
        );
        const newData = JSON.parse(JSON.stringify(data));
        newData.sort((a, b) => {
          const dateA = new Date(a.createdOn);
          const dateB = new Date(b.createdOn);
          return dateB - dateA;
        });

        return newData;
      } else {
        const newData = JSON.parse(JSON.stringify(this.offers));
        newData.sort((a, b) => {
          const dateA = new Date(a.createdOn);
          const dateB = new Date(b.createdOn);
          return dateB - dateA;
        });
        return newData;
      }
    },
    getOfferStatus() {
      return ["Pending", "Accepted", "Rejected"];
    },
    getFormattedPlacements() {
      if (this.selectedCard === "awaited") {
        return this.placements
          .filter(
            (placement) => placement.status?.toLocaleLowerCase() !== "joined"
          )
          .map((placement) => ({
            id: placement.id,
            candidateName:
              placement?.candidate?.firstName +
              " " +
              placement?.candidate?.lastName,
            email: placement?.candidate?.emails[0]
              ? placement?.candidate?.emails[0].email
              : "N/A",
            number: placement?.candidate?.phoneNumbers[0]
              ? placement?.candidate?.phoneNumbers[0].number
              : "N/A",
            jobTitle: placement?.jobApplication?.job.title,
            jobApplicationId: placement?.jobApplication?.id,
            clientName: placement?.jobApplication?.job.client.name,
            clientId: placement?.jobApplication?.job.client.id,
            joiningDate: placement?.expectedJoiningDate,
            status: placement?.status,
          }));
      } else {
        return this.placements
          .filter(
            (placement) => placement.status?.toLocaleLowerCase() === "joined"
          )
          .map((placement) => ({
            id: placement.id,
            candidateName:
              placement.candidate.firstName +
              " " +
              placement.candidate.lastName,
            email: placement.candidate.emails[0]
              ? placement.candidate.emails[0].email
              : "N/A",
            number: placement.candidate.phoneNumbers[0]
              ? placement.candidate.phoneNumbers[0].number
              : "N/A",
            jobTitle: placement.jobApplication.job.title,
            jobApplicationId: placement.jobApplication.id,
            clientName: placement.jobApplication.job.client.name,
            joiningDate: placement.expectedJoiningDate,
            followUps: placement.followUps,
          }));
      }
    },
    sortedPlacementData() {
      const newData = JSON.parse(JSON.stringify(this.getFormattedPlacements));
      newData.forEach((item) => {
        item.followUps.sort((a, b) => {
          const dateA = new Date(a.followUpDate);
          const dateB = new Date(b.followUpDate);
          return dateA - dateB;
        });
      });

      return newData;
    },
    getRecruitersName() {
      let recruiters = this.rollBacks?.map(
        (application) => application.recruiterName
      );
      return [...new Set(recruiters)];
    },

    getFormattedRollbacks() {
      const filterByRecruiter = (application) =>
        this.recruiters ? application.recruiterName === this.recruiters : true;

      const filterBySearch = (application) =>
        this.search
          ? application.jobTitle
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            application.clientName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            application.candidateName
              .toLowerCase()
              .includes(this.search.toLowerCase())
          : true;

      return this.rollBacks.filter(
        (application) =>
          filterByRecruiter(application) && filterBySearch(application)
      );
    },
  },
});
