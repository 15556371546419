import { defineStore } from "pinia";
import api from "@/services/api";
import { toast } from "vue3-toastify";
import VueCookies from "vue-cookies";
// import { useJobOverviewStore } from "../Overview/JobOverviewStore";

export const useClientStore = defineStore("clientStore", {
  state: () => ({
    isActive: true,
    user: VueCookies.get("user"),
    // jobOverviewStore: useJobOverviewStore(),
    isActiveJobs: true,
    clients: [],
    client: null,
    jobs: [],
    activeJobs: [],
    job: {},
    allocation: {},
    clientContacts: [],
    isLoading: false,
    search: "",
    searchJob: null,
    title: "",
    similarData: [],
    showActiveAllocations: true,
    hiringProcess: [],
    addClientDialog: false,
    addHiringProcessDialog: false,
    addDefaultProcessDialog: false,
    addJobDialog: false,
  }),
  actions: {
    toggleAddClientDialog(value) {
      if (typeof value !== "boolean") {
        throw new Error("Value must be a boolean");
      }
      this.addClientDialog = value;
    },
    toggleAllocations() {
      this.showActiveAllocations = !this.showActiveAllocations;
    },
    getClientId() {
      return this.client.id;
    },
    refreshClients() {
      this.getClients();
    },

    async getClients() {
      this.isLoading = true;
      await api
        .get("/clients", {
          params: {
            page: 0,
            size: 500,
            active: this.isActive,
            query: "",
          },
        })
        .then((res) => {
          this.clients = res.data.content;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getAllocatedClients(userId) {
      this.isLoading = true;
      await api
        .get(`clients/allocated/${userId}`)
        .then((res) => {
          this.clients = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.getClients();
    },
    async addClient(client) {
      this.isLoading = true;
      await api
        .post("/clients", { ...client, resubmissionAfterDays: 0 })
        .then(() => {
          this.isLoading = false;
          toast.success("Client Add Successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getClient(clientId) {
      this.isLoading = true;
      await api
        .get(`/clients/${clientId}`)
        .then((res) => {
          this.client = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    viewClient(client) {
      this.client = client;
      this.router.push({ name: "Client Details", params: { id: client.id } });
    },
    async activateClient() {
      var clientId = this.getClientId();
      this.isLoading = true;
      await api
        .put(`/clients/${clientId}/activate`, {})
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.client.active = true;
          this.isLoading = false;
          return this.client;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async deactivateClient(comment) {
      var clientId = this.getClientId();
      this.isLoading = true;
      await api
        .put(`/clients/${clientId}/deactivate`, {
          reason: comment.comment
        })
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.client.active = false;
          this.isLoading = false;
          return this.client;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateClient(clientId, client) {
      this.isLoading = true;
      await api
        .put(`/clients/${clientId}`, client)
        .then((res) => {
          this.client.name = res.data.name;
          this.client.legalName = res.data.legalName;
          this.client.website = res.data.website;
          toast.success("Client Updated", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    //Client Contact
    async getClientContact(clientId) {
      (this.isLoading = true),
        await api
          .get(`/clients/${clientId}/contacts`)
          .then((res) => {
            this.clientcontacts = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async addContact(contact) {
      this.isLoading = true;
      await api
        .post(`/clients/${this.client.id}/contacts`, contact)
        .then((res) => {
          this.client?.contacts?.push(res.data);
          toast.success("Contact added", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getClientContactPersons(clientId) {
      this.isLoading = true;
      await api
        .get(`/clients/${clientId}/contacts`, {})
        .then((res) => {
          this.clientContacts = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async activateDeactivateContactPerson(clientId, contactPerson) {
      this.isLoading = true;
      if (contactPerson.active) {
        await api
          .put(
            `/clients/${clientId}/contacts/${contactPerson.id}/deactivate`,
            {}
          )
          .then((v) => {
            this.client?.contacts?.map((contact) => {
              if (contact.id === contactPerson.id) {
                contact.active = false;
              }
              return contact;
            });
            toast.success(v.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
            return this.client;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      } else {
        await api
          .put(`/clients/${clientId}/contacts/${contactPerson.id}/activate`, {})
          .then((v) => {
            this.client?.contacts?.map((contact) => {
              if (contact.id === contactPerson.id) {
                contact.active = true;
              }
              return contact;
            });
            toast.success(v.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
            return this.client;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      }
    },
    async updateContact(clientId, contactId, updatedContact) {
      this.isLoading = true;
      await api
        .put(`/clients/${clientId}/contacts/${contactId}`, updatedContact)
        .then((res) => {
          this.client?.contacts?.map((contact) => {
            if (contact.id === contactId) {
              contact.name = updatedContact.name;
              contact.email = updatedContact.email;
              contact.number = updatedContact.number;
            }
            return contact;
          });
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
          return this.client;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    //client agreements

    async getClientAgreements(clientId) {
      this.isLoading = true;
      await api
        .get(`/client/${clientId}/agreement`, {})
        .then((res) => {
          this.clients = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addAgreement(agreement) {
      this.isLoading = true;
      await api
        .post(`/client/${this.client.id}/agreement`, agreement)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addPreviousAgreement(agreement) {
      this.isLoading = true;
      await api
        .post(`/client/${this.client.id}/agreement/create-previous`, agreement)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    async activateAgreement(agreementId) {
      var clientId = this.getClientId();
      await api
        .put(`/client/${clientId}/agreement/${agreementId}/activate`, {})
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.agreements.active = true;
          this.isLoading = false;
          return this.client;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async deactivateAgreement(agreementId) {
      var clientId = this.getClientId();
      await api
        .put(`/client/${clientId}/agreement/${agreementId}/deactivate`, {})
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.agreements.active = true;
          this.isLoading = false;
          return this.client;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    //client allocation
    async addAllocation(allocationData) {
      this.isLoading = true;
      await api
        .post(`/client/${this.client.id}/allocation`, allocationData)
        .then((res) => {
          // this.client.allocations.filter((allocation) => allocation.user.id === allocationData.userID);
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async activateAllocation(allocationId) {
      var clientId = this.getClientId();
      this.isLoading = true;
      await api
        .put(`/client/${clientId}/allocation/${allocationId}/activate`, {})
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.client.allocations.map((allocation) => {
            if (allocation.id === allocationId) {
              allocation.active = true;
            }
            return allocation;
          });

          this.isLoading = false;
          return this.client;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async deactivateAllocation(allocationId) {
      var clientId = this.getClientId();
      this.isLoading = true;
      await api
        .put(`/client/${clientId}/allocation/${allocationId}/deactivate`, {})
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.client.allocations.map((allocation) => {
            if (allocation.id === allocationId) {
              allocation.active = false;
            }
            return allocation;
          });
          this.isLoading = false;
          return this.client;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    //client Jobs
    refreshClientJobs(clientId) {
      this.getClientJobs(clientId);
    },
    toggleClientJobActiveStatus(clientId) {
      this.isActiveJobs = !this.isActiveJobs;
      this.getClientJobs(clientId);
    },
    getClientJobsURL(clientId) {
      if (this.isActiveJobs) {
        return `/clients/${clientId}/jobs/active`;
      } else {
        return `/clients/${clientId}/jobs/in-active`;
      }
    },
    async getClientJobs(clientId) {
      this.isLoading = true;
      await api
        .get(this.getClientJobsURL(clientId))
        .then((res) => {
          this.jobs = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getCLientActiveJobs(clientId) {
      this.isLoading = true;
      await api
        .get(`/clients/${clientId}/jobs/active`)
        .then((res) => {
          this.jobs = res.data.jobs;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async checkJobTitle(clientId, title) {
      this.isLoading = true;
      await api
        .get(`/clients/${clientId}/check/${title}`)
        .then((res) => {
          this.similarData = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addJob(clientId, job) {
      this.isLoading = true;
      await api
        .post(`/clients/${clientId}/job`, job)
        .then((res) => {
          this.jobs.push(res.data);
          this.isLoading = false;
          toast.success("Job Added Successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getJob(jobId) {
      this.isLoading = true;
      await api
        .get(`/jobs/${jobId}`)
        .then((res) => {
          this.job = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async jobAllocation(jobId, allocationData) {
      this.isLoading = true;
      await api
        .post(`/job/${jobId}/allocation/allocate`, allocationData)
        .then((res) => {
          this.job.allocations.push(res.data);
          this.isLoading = false;
          toast.success("Job Allocated Successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async jobDeAllocation(allocationId) {
      this.isLoading = true;
      await api
        .put(`/job/${this.job.id}/allocation/${allocationId}/deallocate`)
        .then((res) => {
          if (res.status === 200) {
            let index = this.job.allocations.findIndex(
              (allocation) => allocation.id === allocationId
            );
            if (index !== -1) {
              this.job.allocation.splice(index, 1);
            }

            toast.success("Job De-Allocated Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async changeUserRole(allocationId, role) {
      this.isLoading = true;
      await api
        .put(
          `/job/${this.job.id}/allocation/${allocationId}/change-role/${role}`
        )
        .then((res) => {
          if (res.status === 200) {
            this.allocation = this.job.allocations.find(
              (allocation) => allocation.id === allocationId
            );
            this.allocation.role = role.toUpperCase();

            toast.success(`change role to ${role}`, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }

          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
      this.allocation.role = role.toUpperCase();
    },

    // client Hiring Process
    async getHiringProcess(clientId) {
      await api
        .get(`/clients/${clientId}/hiring-process`)
        .then((res) => {
          this.hiringProcess = res.data;
        })
        .catch((err) => {
          return err;
        });
    },
    async addHiringProcess(clientId, process) {
      (this.isLoading = true),
        await api
          .post(`/clients/${clientId}/hiring-process`, process)
          .then((res) => {
            this.hiringProcess.push(res.data);
            toast.success("Process Added", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },

    // getClientJobsAllApplications() {
    //   let result = [];
    //   this.jobs?.forEach(async (job) => {
    //     this.isLoading = true;
    //     await api
    //       .get(`/overview/job/${job.id}`)
    //       .then((res) => {
    //         result.push(res.data);
    //         this.isLoading = false;
    //       })
    //       .catch((err) => {
    //         this.isLoading = false;
    //         return err;
    //       });
    //   });
    //   return result;
    // },
  },
  getters: {
    formattedClientsData() {
      const searchTerm = this.search?.toLowerCase();
      return this.clients.filter(
        (client) =>
          client.name.toLowerCase().includes(searchTerm) ||
          client.legalName.toLowerCase().includes(searchTerm)
      );
    },
    getSearchedClientJobs() {
      const searchTerm = this.searchJob?.toLowerCase();
      return searchTerm
        ? this.jobs.filter((job) =>
            job.title.toLowerCase().includes(searchTerm)
          )
        : this.jobs;
    },
    clientInfo() {
      return this.client;
    },
    contacts() {
      return this.client?.contacts;
    },

    allocations() {
      return this.client &&
        this.client.allocations &&
        Array.isArray(this.client.allocations) &&
        this.client.allocations.length > 0
        ? this.client.allocations.filter((allocation) => {
            return allocation.active === this.showActiveAllocations;
          })
        : null;
    },

    jobAllocations() {
      return this.job.allocations;
    },
    hiringProcessData() {
      return this.hiringProcess;
    },
    viewerAcess() {
      return (
        this.client?.allocations?.find(
          (allocation) => allocation.userId === this.user.id
        )?.role === "Viewer" ?? null
      );
    },

    // formatJobsApplications() {
    //   let result = [];
    //   this.getClientJobsAllApplications.forEach((job) => {
    //     let fomattedJobApplicationsArray = [];
    //     let formattedApplications = job.jobApplications.map((application) => ({
    //       title: job.title,
    //       ...application,
    //     }));

    //     fomattedJobApplicationsArray = fomattedJobApplicationsArray.concat(
    //       formattedApplications
    //     );
    //     result = result.concat(fomattedJobApplicationsArray);
    //   });
    //   console.log([...new Set(result)]);

    //   // return [...new Set(result)];
    // },
  },
});
