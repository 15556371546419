<template>
  <v-dialog activator="parent" v-model="dialog" max-width="350">
    <v-card color="#303030">
      <div class="d-flex justify-center ma-2">
        <div>
          <v-img
            :src="whatsApp"
            :width="50"
            :height="40"
            class="d-flex align-self-end"
            aspect-ratio="4/3"
            contain
          ></v-img>
        </div>

        <h3 class="font-weight-regular align-self-center">WhatsApp Chat</h3>
      </div>
      <v-avatar
        style="
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          z-index: 10;
        "
        variant="tonal"
        size="small"
        class="ma-1 elevation-16 closeBtn"
        @click="dialog = false"
        rounded="lg"
      >
        <v-icon size="x-small">mdi-close</v-icon>
      </v-avatar>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <CustomTextFieldComponent
              v-model.stop="number"
              type="text"
              @input="$event.target.value = $event.target.value.slice(0, 10)"
              :maxlength="10"
              :rules="[
                requiredValidator,
                mobileNumberValidator,
                nonNegativeNumberValidator,
              ]"
              label="Number"
            />
          </v-col>
          <v-textarea
            v-model="message"
            rows="2"
            variant="outlined"
            color="#b2d250"
            rounded="lg"
            placeholder="Type Something..."
          />
        </v-row>
        <v-btn variant="tonal" block color="#b2d250" @click="onChat()"
          >Chat</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import whatsApp from "@/assets/icons/whatsapp.png";
import { requiredValidator } from "@/composables/Validators";
import { mobileNumberValidator } from "@/composables/Validators";
import { nonNegativeNumberValidator } from "@/composables/Validators";
import { ref } from "vue";
const dialog = ref(false);

const number = ref();
const message = ref("");

const onChat = () => {
  window.open(
    `https://wa.me/91${number.value}?text=${encodeURIComponent(message.value)}`
  );
  dialog.value = false;
};
</script>

<style lang="scss" scoped>
.v-img__img {
  width: 50%;
}
</style>
