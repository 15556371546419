<template>
  <v-dialog v-model="previousJobApplicationsStore.addCandidateDialog" max-width="400">
    <v-card color="#303030">
      <v-card-text>
        <!-- 👉 Title -->
        <h3 class="font-weight-regular text-center mb-4">Add Candidate</h3>
        <DialogCloseBtn @close-dialog="closeForm()" />

        <!--  Form -->
        <v-form ref="refForm">
          <v-row dense>
            <!--   name -->
            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="firstName"
                :rules="[requiredValidator]"
                @input="firstName = $event.target.value.replace(/\s+/g, ' ')"
                label="First Name"
              />
            </v-col>

            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="lastName"
                @input="lastName = $event.target.value.replace(/\s+/g, ' ')"
                :rules="[requiredValidator]"
                label="Last Name"
              />
            </v-col>
            <!-- 👉 gender -->

            <v-col cols="12" class="pt-0">
              <CustomSelectComponent
                v-model="gender"
                label="Gender"
                :rules="[requiredValidator]"
                :items="['Male', 'Female']"
              ></CustomSelectComponent>
            </v-col>
            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="email"
                :rules="[requiredValidator, emailValidator]"
                label="Email"
              />
            </v-col>
            <v-col cols="12">
              <CustomTextFieldComponent
                v-model.stop="phoneNumber"
                type="text"
                @input="$event.target.value = $event.target.value.slice(0, 10)"
                :maxlength="10"
                :rules="[
                  requiredValidator,
                  mobileNumberValidator,
                  nonNegativeNumberValidator,
                ]"
                label="Number"
              />
            </v-col>
            <v-col cols="12">
              <CustomAutoComplete
                v-model="profession"
                :rules="[requiredValidator]"
                :items="corporateProfessions"
                label="Profession"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center gap-3">
        <v-btn variant="tonal" color="#74a942" @click="onSubmit()"> <p>Submit</p></v-btn>
        <v-btn variant="tonal" color="red" @click="closeForm()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmationDialog"
    @action-yes="onConfirm()"
    @update-model="confirmationDialog = false"
    title="Add Candidate"
    text="Want to add
  Candidate ?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import { corporateProfessions } from "@/composables/CandidateProfession";
import CustomSelectComponent from "@/components/SharedComponent/components/CustomSelectComponent.vue";
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import CustomAutoComplete from "@/components/SharedComponent/components/CustomAutoComplete.vue";
import { ref } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { emailValidator } from "@/composables/Validators";
import { mobileNumberValidator } from "@/composables/Validators";
import { nonNegativeNumberValidator } from "@/composables/Validators";
import { useCandidateStore } from "@/store/Candidate/CandidateStore";
import { usePreviousJobApplicationsStore } from "@/store/AddPreviousJobApplications";
const confirmationDialog = ref(false);
const previousJobApplicationsStore = usePreviousJobApplicationsStore();
const candidateStore = useCandidateStore();
const refForm = ref();
const firstName = ref("");
const lastName = ref("");
const gender = ref("");
const email = ref("");
const phoneNumber = ref("");
const profession = ref("Default");
const closeForm = () => {
  firstName.value = "";
  lastName.value = "";
  gender.value = "";
  email.value = "";
  phoneNumber.value = "";
  profession.value = "";
  previousJobApplicationsStore.addCandidateDialog = false;
};

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmationDialog.value = true;
    }
  });
};

const onConfirm = () => {
  let data = {
    firstName: firstName.value,
    lastName: lastName.value,
    gender: gender.value,
    email: email.value,
    phoneNumber: phoneNumber.value,
    profession: profession.value,
  };
  confirmationDialog.value = false;
  previousJobApplicationsStore.addCandidateDialog = false;
  candidateStore.addCandidateEndpoint(data);
};
</script>
