<template>
  <v-dialog v-model="dialog" activator="parent" max-width="500">
    <v-card>
      <DialogCloseBtn @close-dialog="dialog = false" />
      <v-container fluid class="pa-2">
        <h3 class="font-weight-regular text-center pb-3">Update Job Application</h3>

        <v-row dense>
          <v-col cols="6">
            <v-card color="#303030" style="cursor: pointer">
              <v-card-text>
                <div class="">
                  <v-img :src="calendar" class="mx-auto" :width="40" />
                </div>
                <h3 class="font-weight-regular text-center mt-2">Update Date</h3>
              </v-card-text>
              <UpdateJobApplicationDate />
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card color="#303030" style="cursor: pointer">
              <v-card-text>
                <div class="">
                  <v-img :src="interview" class="mx-auto" :width="40" />
                </div>
                <h3 class="font-weight-regular text-center mt-2">Update Interview</h3>
              </v-card-text>
            </v-card>
            <CommentDialog
              title="Update Interview"
              @close="active = false"
              @update-interview="interviewStore.updateCompletedInterview"
            />
          </v-col>
          <v-col cols="6">
            <v-card color="#303030" style="cursor: pointer">
              <v-card-text>
                <div class="">
                  <v-img :src="calendar" class="mx-auto" :width="40" />
                </div>
                <h3 class="font-weight-regular text-center mt-2">Create Closure</h3>
              </v-card-text>
              <CreateClosure />
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              @click="confirmDialogStage = true"
              color="#303030"
              style="cursor: pointer"
            >
              <v-card-text>
                <div class="">
                  <v-img :src="calendar" class="mx-auto" :width="40" />
                </div>
                <h3 class="font-weight-regular text-center mt-2">
                  {{
                    jobApplicationStore.jobApplication?.active
                      ? "De-activate"
                      : "Activate"
                  }}
                </h3>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="changeApplicationStatus()"
    @update-model="confirmDialogStage = false"
    title="Update status of application"
    text="Are you sure you want to Update status of application?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CommentDialog from "@/components/JobAplication/dialogs/CommentDialog.vue";
import UpdateJobApplicationDate from "@/components/JobAplication/dialogs/UpdateJobApplicationDateDialog.vue";
import CreateClosure from "@/components/JobAplication/dialogs/CreateClosureDialog.vue";

import { ref } from "vue";
import calendar from "@/assets/icons/calendar.png";
import interview from "@/assets/icons/interview.png";
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import { useInterviewStore } from "@/store/JobApplications/InterviewStore";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
const interviewStore = useInterviewStore();
const jobApplicationStore = useJobApplicationStore();
const dialog = ref(false);
const confirmDialogStage = ref(false);

const changeApplicationStatus = async () => {
  confirmDialogStage.value = false;
  let status = jobApplicationStore.jobApplication?.active ? false : true;
  await jobApplicationStore.changejobApplicationsStatus(status);
};
</script>
