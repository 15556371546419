<template>
  <v-dialog v-model="dialog" max-width="400" activator="parent">
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="dialog = false" />

      <v-card-text class="pb-0">
        <h3 class="font-weight-regular text-center mb-4">Accept Offer</h3>

        <v-form ref="refForm">
          <v-row no-gutters>
            <v-col cols="12">
              <CustomTextFieldComponent
                label="Accepted Amount"
                v-model="acceptedSalary"
                type="text"
                inputmode="numeric"
                @input="$event.target.value = $event.target.value.slice(0, 10)"
                v-maska:[vMaskOptions]
                :maxlength="10"
                :rules="[requiredValidator, lengthOfSsalary]"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Comment(Optional)"
                v-model="comment"
                variant="outlined"
                @input="comment = $event.target.value.replace(/\s+/g, ' ')"
                hide-details="auto"
                color="#b2d250 "
                rows="3"
                counter="30"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onSubmit()" variant="tonal" color="#74a942"> Submit </v-btn>
        <v-btn @click="dialog = false" variant="tonal" color="red"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="onConfirm()"
    @update-model="confirmDialogStage = false"
    title="Accept Offer"
    text="Are you sure you want to Accept Offer ?"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import { ref, defineProps, defineEmits } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { lengthOfSsalary } from "@/composables/Validators";
import { vMaska } from "maska";

defineProps(["dialog", "title", "offerData"]);
const dialog = ref(false);
const acceptedSalary = ref();
const comment = ref("");
const confirmDialogStage = ref(false);
const refForm = ref();
const emits = defineEmits(["accept-offer"]);
const vMaskOptions = {
  mask: "99,99,99#",
  tokens: {
    9: { pattern: /[0-9]/, repeated: true },
  },
  reversed: true,
};
const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialogStage.value = true;
    }
  });
};
const onConfirm = () => {
  let data = {
    acceptedSalary: Number(acceptedSalary.value?.split(",").join("")),
    comment: comment.value ? comment.value : "Offer has been accepted",
  };
  emits("accept-offer", data);
  confirmDialogStage.value = false;
  dialog.value = false;
};
</script>
