<template>
  <v-dialog
    v-model="previousJobApplicationsStore.candidateDialog"
    persistent
    max-width="600"
  >
    <v-card color="#343435" height="90vh" theme="dark">
      <v-card-text>
        <DialogCloseBtn
          @close-dialog="previousJobApplicationsStore.candidateDialog = false"
        />
        <v-row>
          <v-col cols="12" lg="9" md="9" sm="9">
            <h3 class="font-weight-regular">Search Candidate</h3>
            <div class="d-flex">
              <v-form ref="refForm" @submit.prevent class="w-100 me-4">
                <CustomTextFieldComponent
                  class="mt-3"
                  v-model="searchValue"
                  :color="'orange-lighten-3'"
                  density="default"
                  placeholder="Search by Name, Number or Email"
                  @keyup.prevent.enter="searchCandidate()"
                />
              </v-form>
              <v-btn
                size="small"
                class="align-self-center d-flex justify-center"
                variants="tonal"
                color="#b2d250"
                icon="mdi-plus"
                @click="previousJobApplicationsStore.addCandidateDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card
          max-height="79vh"
          class="overflow-auto"
          v-if="previousJobApplicationsStore.searchedCandidates?.length > 0"
        >
          <v-card
            v-for="candidate in previousJobApplicationsStore.searchedCandidates"
            :key="candidate.id"
            @click="previousJobApplicationsStore.selectCandidate(candidate)"
            color="transparent"
            border
          >
            <v-card-text class="pa-2">
              <div class="d-flex pb-0">
                <h3 class="font-weight-regular align-self-center" style="cursor: pointer">
                  {{ candidate?.name }}
                </h3>
              </div>
              <div class="pa-0 d-flex">
                <div class="me-3">
                  <v-list class="pa-0">
                    <v-list-item
                      v-for="number in candidate.phoneNumbers"
                      :key="number"
                      class="pb-1"
                      density="comapact"
                    >
                      +91 {{ number }}
                    </v-list-item>
                  </v-list>
                </div>
                <v-divider vertical></v-divider>
                <div class="me-3">
                  <v-list class="pa-0">
                    <v-list-item
                      v-for="email in candidate.emails"
                      :key="email"
                      class="pb-1"
                      density="comapact"
                    >
                      {{ email }}
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card>
        <div v-else>
          <div
            style="
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
            "
          >
            <div>
              <div class="d-flex justify-center">
                <v-avatar size="65" icon="mdi-account" variant="tonal" rounded="lg">
                  <v-icon size="large">mdi-account</v-icon>
                </v-avatar>
              </div>

              <h2 class="font-weight-regular pa-2">Candidate Not Found</h2>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <AddCandidateDialog />

  <LoaderDialog :model-value="previousJobApplicationsStore.isLoading" />
</template>

<script setup>
import AddCandidateDialog from "@/components/SharedComponent/PreviousJobApplications/dialogs/AddCandidateDialog.vue";
import LoaderDialog from "@/components/SharedComponent/dialogs/LoaderDialog.vue";
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import { ref, watch } from "vue";
import { usePreviousJobApplicationsStore } from "@/store/AddPreviousJobApplications";

const previousJobApplicationsStore = usePreviousJobApplicationsStore();
const tabs = ref(0);
const searchValue = ref(null);
const refForm = ref();
const searchCandidate = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      previousJobApplicationsStore.searchCandidate(searchValue.value);
    }
  });
};
watch(
  () => [tabs.value, previousJobApplicationsStore.candidateDialog],
  () => {
    searchValue.value = null;
    if (!previousJobApplicationsStore.candidateDialog) {
      previousJobApplicationsStore.searchedCandidates = [];
    }
  }
);
watch(
  () => searchValue.value,
  () => {
    if (!searchValue.value) {
      previousJobApplicationsStore.searchedCandidates = [];
    }
  }
);
</script>

<style scoped>
.v-tab--selected .v-tab__slider {
  height: 1px;
}
.v-tabs.v-tabs-pill .v-tab.v-btn:hover {
  color: #b6d230;
}
.v-tabs.v-tabs-pill .v-slide-group-item--active.v-tab--selected {
  background-color: #b6d230 !important;
  border-radius: 10px;
  color: rgb(43, 43, 45) !important;
}
:deep(.v-list-item) {
  min-height: none !important;
}
</style>
