<script setup>
import { computed, ref, watch } from "vue";
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import LoaderDialog from "@/components/SharedComponent/dialogs/LoaderDialog.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import AcceptOfferDialog from "@/components/JobAplication/dialogs/AcceptOfferDialog.vue";
import RequestToAddCommonStatusDialog from "@/components/JobAplication/dialogs/RequestToAddCommonStatusDialog.vue";
import SelectTierTypeDialog from "@/components/JobAplication/dialogs/SelectTierTypeDialog.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import AppStepper from "@/components/SharedComponent/components/AppStepper.vue";
import RejectReasonDialog from "@/components/JobAplication/dialogs/RejectReasonDialog.vue";
import CommentDialog from "@/components/JobAplication/dialogs/CommentDialog.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import RollbackRequestDialog from "@/components/JobAplication/dialogs/RollbackRequestDialog.vue";
import AddOfferDialog from "@/components/JobAplication/dialogs/AddOfferDialog.vue";
import JoinDialog from "@/components/JobAplication/dialogs/JoinDialog.vue";
import InterviewScheduledComponent from "@/components/JobAplication/components/InterviewScheduledComponent.vue";
import InterviewScheduleForm from "@/components/JobAplication/components/InterviewScheduleForm.vue";
import InterviewCompletedComponent from "@/components/JobAplication/components/InterviewCompletedComponent.vue";
import InterviewPassedComponent from "@/components/JobAplication/components/InterviewPassedComponent.vue";
import { IconExclamationCircle, IconCircleCheck } from "@tabler/icons-vue";
import { requiredValidator } from "@/composables/Validators";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { useJobApplicationApprovalPendingStore } from "@/store/JobApplications/JobApplicationApprovalPending";
import { getYYYYMMDDFormat } from "@/composables/DateTime";
import { toast } from "vue3-toastify";
import { useRouter } from "vue-router";
import { useJobApplicationsStore } from "@/store/JobApplications/JobApplicationsStore";
import { commentLengthValidator } from "@/composables/Validators";
import { inject } from "vue";

const $cookies = inject("$cookies");
const jobApplicationStore = useJobApplicationStore();
const jobApplicationsStore = useJobApplicationsStore();
const jobApprovalPendingStore = useJobApplicationApprovalPendingStore();
const router = useRouter();
const userId = $cookies.get("user").id;

//ref forms
const refAddPlacementForm = ref();
const refRemarkForm = ref();

// dialogs
const applicationSharedConfirmDialog = ref(false);
const shortlistJobApplicationDialog = ref(false);
const acceptOfferDialog = ref(false);
const addPlacementDialog = ref(false);
const dialog = ref(false);
const isCurrentStepValid = ref(true);
const remarkDialog = ref(false);
const selectedStatus = ref(null);
const confirmChangeJobApplicationDialog = ref(false);
const dateTime = ref();
const comment = ref("");
const remark = ref("");
const slectedJobApplicationId = ref("");

const convertDate = computed(() => {
  return getYYYYMMDDFormat(dateTime.value) === "Invalid date" ||
    getYYYYMMDDFormat(dateTime.value).includes("1970-01-01")
    ? ""
    : getYYYYMMDDFormat(dateTime.value);
});
const iconsSteps = [
  {
    title: "Submission",
    icon: IconExclamationCircle,
  },
  {
    title: "Screening",
    icon: IconExclamationCircle,
  },
  {
    title: "Level 1",
    icon: IconCircleCheck,
  },
  {
    title: "Level 2",
    icon: IconCircleCheck,
  },
  {
    title: "Level 3",
    icon: IconCircleCheck,
  },
  {
    title: "Selection",
    icon: IconCircleCheck,
  },
  {
    title: "Joined",
    icon: IconCircleCheck,
  },
];
const index = computed(() => {
  let value = jobApplicationStore.showApplicationStatusScreen();
  if (value) {
    return 7;
  } else {
    return iconsSteps.findIndex(
      (step) =>
        step.title === jobApplicationStore.jobApplication.currentHiringStage
    );
  }
});
const currentStep = ref(index);

const jobApplicationsCommonStatus = computed(() => {
  const data = localStorage.getItem("jobApplicationsStatus");
  return JSON.parse(data);
});

const changeJobApplicationStatus = (status) => {
  selectedStatus.value = status;

  if (!status.remarkRequired) {
    confirmChangeJobApplicationDialog.value = true;
  }
};
const changeJobApplicationStatusWithRemark = () => {
  remark.value
    ? (confirmChangeJobApplicationDialog.value = true)
    : (confirmChangeJobApplicationDialog.value = false);
};

const OnConfirmChangeJobApplicationStatus = async () => {
  //endpoint
  let data = {
    stage: selectedStatus.value.stage,
    status: selectedStatus.value.name,
    comment: remark.value,
  };
  await jobApplicationStore.commonJobApplicationStatus(data);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};

const approvedJobApplication = async (data) => {
  await jobApprovalPendingStore.approveJobApplication(
    jobApplicationStore.jobApplication.id,
    data
  );
};
const rejectJobApplication = async (data) => {
  let req = {
    type: "Senior Rejected",
    comment: data.comment,
  };
  await jobApprovalPendingStore.rejectJobApplication(
    jobApplicationStore.jobApplication.id,
    req
  );
};

const applicationShared = async () => {
  if (convertDate.value) {
    let data = {
      sharedOn: convertDate.value,
      comment: "Application Shared",
    };
    applicationSharedConfirmDialog.value = false;
    await jobApplicationStore.sharedWithClientJobApplication(
      jobApplicationStore.jobApplication.id,
      data
    );
    await jobApplicationStore.getJobApplication(
      jobApplicationStore.jobApplication.id
    );
  } else {
    toast.error("Required Date", {
      autoClose: 1500,
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      transition: toast.TRANSITIONS.ZOOM,
    });
  }
};

const onShortlisted = async () => {
  let shortlisted = {
    shortlistedOn: getYYYYMMDDFormat(new Date()),
    comment: "",
  };
  shortlistJobApplicationDialog.value = false;
  await jobApplicationStore.shortlistedJobApplication(
    jobApplicationStore.jobApplication.id,
    shortlisted
  );
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
const screeningReject = async (data) => {
  await jobApplicationStore.screeningRejectJobApplication(
    jobApplicationStore.jobApplication.id,
    data
  );
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};

//offer
const createOffer = async (offer) => {
  await jobApplicationStore.addOffer(offer);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
  dialog.value = false;
};
const acceptOffer = async (offer) => {
  await jobApplicationStore.acceptOffer(offer);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
  dialog.value = false;
};
const rejectOffer = async (offer) => {
  await jobApplicationStore.rejectOffer(offer);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
  dialog.value = false;
};

//placement
const placementPage = async () => {
  jobApplicationsStore.overlay = false;
  await jobApplicationStore.getPlacements(userId);
  router.push("/placements");
};
const confirmAddPlacement = () => {
  refAddPlacementForm.value?.validate().then((valid) => {
    if (valid.valid) {
      addPlacementDialog.value = true;
    }
  });
};
const addPlacement = async () => {
  let data = {
    jobApplicationId: jobApplicationStore.jobApplication.id,
    expectedJoiningDate: convertDate.value,
    comment: comment.value ? comment.value : "Expected Joining Date",
  };
  addPlacementDialog.value = false;
  await jobApplicationStore.addPlacement(data);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
  dialog.value = false;
};
const WithdrawPlacement = async (reason) => {
  await jobApplicationStore.withdrawPlacement(
    jobApplicationStore.jobApplication.id,
    reason
  );
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
const rejectPlacement = async (reason) => {
  await jobApplicationStore.rejectPlacement(
    jobApplicationStore.jobApplication.id,
    reason
  );
};
const holdPlacement = async (reason) => {
  await jobApplicationStore.holdPlacement(
    jobApplicationStore.jobApplication.id,
    reason
  );
};
watch(
  () => remarkDialog.value,
  () => {
    if (remarkDialog.value === false) {
      selectedStatus.value = null;
    }
  }
);
</script>

<template>
  <VCard height="75vh" color="#252525">
    <v-card-text class="pr-6">
      <!-- 👉 Stepper -->
      <v-row no-gutters>
        <v-col cols="12" lg="11" md="11" sm="11">
          <AppStepper
            v-model:current-step="currentStep"
            :items="iconsSteps"
            :is-active-step-valid="isCurrentStepValid"
        /></v-col>
        <v-col cols="12" lg="1" md="1" sm="1" align="end">
          <div class="d-flex">
            <v-btn
              icon="mdi-dots-vertical"
              variant="tonal"
              size="small"
              :disabled="jobApplicationStore.showCommonStatusBtn()"
              class="me-3"
            >
              <v-icon>mdi-dots-vertical</v-icon>
              <v-menu activator="parent" location="bottom">
                <v-card color="transparent" max-height="400">
                  <v-list>
                    <v-hover
                      v-slot="{ isHovering, props }"
                      v-for="status in jobApplicationsCommonStatus"
                      :key="status.id"
                    >
                      <v-list-item class="user-list-name" v-bind="props">
                        <v-chip
                          @click="changeJobApplicationStatus(status)"
                          :variant="isHovering ? 'tonal' : 'text'"
                          class="w-100 rounded"
                          size="large"
                          :style="isHovering ? `cursor: pointer` : ''"
                        >
                          <p
                            class="text-button text-capitalize"
                            block
                            flat
                            variant="text"
                          >
                            {{ status.name }}
                          </p>
                        </v-chip>

                        <v-dialog
                          v-model="remarkDialog"
                          activator="parent"
                          max-width="450"
                          v-if="status.remarkRequired"
                        >
                          <v-card color="#303030">
                            <DialogCloseBtn
                              @close-dialog="remarkDialog = false"
                            />

                            <v-card-text class="pa-4 pb-0">
                              <h3 class="font-weight-regular text-center mb-2">
                                Remark
                              </h3>
                              <v-form ref="refRemarkForm" @submit.prevent>
                                <v-row no-gutters>
                                  <v-col cols="12">
                                    <v-textarea
                                      variant="outlined"
                                      color="#b2d250"
                                      label="Remark"
                                      @input="
                                        remark = $event.target.value.replace(
                                          /\s+/g,
                                          ' '
                                        )
                                      "
                                      autofocus
                                      :rules="[
                                        requiredValidator,
                                        commentLengthValidator,
                                      ]"
                                      v-model="remark"
                                      counter="30"
                                    />
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>

                            <v-card-actions
                              class="d-flex justify-center flex-wrap gap-3"
                            >
                              <v-btn
                                @click="changeJobApplicationStatusWithRemark()"
                                variant="tonal"
                                color="#74a942"
                              >
                                Submit
                              </v-btn>
                              <v-btn
                                @click="remarkDialog = false"
                                variant="tonal"
                                color="red"
                              >
                                Cancel
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item>
                    </v-hover>
                    <v-card-actions>
                      <v-btn
                        block
                        variant="tonal"
                        size="small"
                        color="#b2d250"
                        prepend-icon="mdi-plus"
                        >Request Status
                        <RequestToAddCommonStatusDialog />
                      </v-btn>
                    </v-card-actions>
                  </v-list>
                </v-card>
              </v-menu>
            </v-btn>
            <v-btn
              icon="mdi-alert-circle-outline"
              variant="tonal"
              color="orange"
              size="small"
              ><v-icon>mdi-alert-circle-outline</v-icon>
              <v-tooltip activator="parent" location="bottom">
                Help & Support
              </v-tooltip>
              <RollbackRequestDialog />
              <!-- <HelpAndSupport/> -->
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <VDivider />

    <VCardText class="pa-1">
      <VWindow v-model="currentStep" class="disable-tab-transition">
        <VWindowItem style="height: 400px">
          <div v-if="jobApplicationStore.showCurrentStatusScreen('Initiated')">
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center ma-3">
                    Is the Application shared with the Client?
                  </h3>
                  <VueDatePicker
                    v-model="dateTime"
                    :is-24="false"
                    dark
                    :clearable="false"
                    teleport-center
                    month-name-format="long"
                    ><template #dp-input>
                      <CustomTextFieldComponent
                        type="text"
                        hide-details="auto"
                        readonly
                        :model-value="convertDate"
                        prepend-inner-icon="mdi-calendar"
                        :rules="[requiredValidator]"
                        class="pb-0"
                        label="Select Date"
                      >
                      </CustomTextFieldComponent>
                    </template>
                  </VueDatePicker>
                  <v-textarea
                    label="Comment"
                    rows="2"
                    class="mt-3"
                    @input="comment = $event.target.value.replace(/\s+/g, ' ')"
                    auto-grow
                    v-model="comment"
                    counter="30"
                  />
                  <div class="d-flex justify-center mt-8">
                    <v-btn
                      color="green"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="applicationSharedConfirmDialog = true"
                      >Next</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Senior Rejected')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center ma-3">
                    Senior Reject the Candidate
                  </h3>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Senior Approved')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center mb-3">
                    Have you shared the application with our valued client?
                  </h3>
                  <div class="mx-auto w-75">
                    <h4 class="font-weight-regular text-center mb-2">
                      Select the job application shared date
                    </h4>
                    <div>
                      <VueDatePicker
                        v-model="dateTime"
                        :is-24="false"
                        dark
                        :clearable="false"
                        :enable-time-picker="false"
                        teleport-center
                        month-name-format="long"
                        ><template #dp-input>
                          <CustomTextFieldComponent
                            type="text"
                            hide-details="auto"
                            readonly
                            :model-value="convertDate"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[requiredValidator]"
                            class="pb-0"
                            label="Select Date"
                          >
                          </CustomTextFieldComponent>
                        </template>
                      </VueDatePicker>
                    </div>
                  </div>
                  <div class="d-flex justify-center mt-5">
                    <v-btn
                      color="green"
                      variant="tonal"
                      @click="applicationSharedConfirmDialog = true"
                      >Shared</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem style="height: 400px">
          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Shared with Client')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center ma-1">
                    Kindly Elevate Application Status
                  </h3>
                  <div class="d-flex justify-center mt-6">
                    <h3 class="font-weight-regular me-2">
                      Has the candidate been
                    </h3>

                    <v-btn
                      color="green"
                      variant="tonal"
                      class="me-2"
                      size="small"
                      @click="shortlistJobApplicationDialog = true"
                      >Shortlisted
                    </v-btn>
                    <h3 class="font-weight-regular me-2">or</h3>
                    <v-btn color="red" variant="tonal" class="me-2" size="small"
                      >Rejected

                      <CommentDialog
                        title="Screening Reject"
                        @screening-reject="screeningReject"
                      />
                    </v-btn>
                    <h3 class="font-weight-regular">after screening?</h3>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Shortlisted')"
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div style="width: 60%">
                  <h3 class="font-weight-regular text-center ma-1">
                    Fantastic! please share the details of the upcoming
                    interview with Date & Time?
                  </h3>
                  <InterviewScheduleForm level="L1" />
                </div>
              </div>
            </v-card-text>
          </div>

          <div v-if="jobApplicationStore.showCurrentStatusScreen('Rejected')">
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    Dear Recruiter, kindly note that the candidate's screening
                    status is marked as rejected
                  </h2>
                  <h2 class="font-weight-regular text-center ma-3">
                    Thank you for your attention to this matter.
                  </h2>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen(
                'Interview Scheduled'
              ) ||
              jobApplicationStore.showCurrentStatusScreen(
                'Interview Rescheduled'
              )
            "
          >
            <InterviewScheduledComponent
              :interviews="jobApplicationStore.jobApplication.interviews"
              level="Level 1"
            />
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Interview Completed')
            "
          >
            <InterviewCompletedComponent
              :interviews="jobApplicationStore.jobApplication.interviews"
              level="Level 1"
            />
          </div>
          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Interview Passed')
            "
          >
            <InterviewPassedComponent level="L1" />
          </div>
        </VWindowItem>

        <VWindowItem>
          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen(
                'Interview Scheduled'
              ) ||
              jobApplicationStore.showCurrentStatusScreen(
                'Interview Rescheduled'
              )
            "
          >
            <InterviewScheduledComponent
              :interviews="jobApplicationStore.jobApplication.interviews"
              level="Level 2"
            />
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Interview Completed')
            "
          >
            <InterviewCompletedComponent
              :interviews="jobApplicationStore.jobApplication.interviews"
              level="Level 2"
            />
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Interview Passed')
            "
          >
            <InterviewPassedComponent level="L2" />
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Interview Failed')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3 me-3">
                    Unfortunately, the candidate wasn't successful in the
                    interview this time.
                  </h2>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen(
                'Interview Scheduled'
              ) ||
              jobApplicationStore.showCurrentStatusScreen(
                'Interview Rescheduled'
              )
            "
          >
            <InterviewScheduledComponent
              :interviews="jobApplicationStore.jobApplication.interviews"
              level="Level 3"
            />
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Interview Completed')
            "
          >
            <InterviewCompletedComponent
              :interviews="jobApplicationStore.jobApplication.interviews"
              level="Level 3"
            />
          </div>

          <!-- selected  -->
          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Interview Passed')
            "
          >
            <InterviewPassedComponent level="L3" />
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Interview Failed')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3 me-3">
                    Unfortunately, the candidate wasn't successful in the
                    interview this time.
                  </h2>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <!-- offer  -->
          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Selected')"
          >
            <v-card-text class="pa-2">
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3 mb-0">
                    Congratulations!🎉 Well done on reaching this milestone.
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2">
                    Could you please provide the details of the offer? Exciting
                    times ahead!
                  </h3>
                  <div class="d-flex justify-center">
                    <v-btn color="green" variant="tonal" @click="dialog = true"
                      >Create Offer
                      <AddOfferDialog
                        @add-offer="createOffer"
                        :dialog="dialog"
                        title="Add OfferF"
                      />
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Offer Received')"
          >
            <v-card-text class="pa-2">
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-3">
                    Did the candidate confirm their acceptance of the offer?
                  </h2>
                  <div class="d-flex justify-center ma-2">
                    <v-btn
                      color="green"
                      variant="tonal"
                      class="me-3"
                      @click="acceptOfferDialog = true"
                      >Accept
                      <AcceptOfferDialog
                        :offerData="offer"
                        @accept-offer="acceptOffer"
                        :dialog="acceptOfferDialog"
                      />
                    </v-btn>
                    <v-btn color="red" variant="tonal"
                      >Reject
                      <CommentDialog
                        title="Reject Offer"
                        @reject-offer="rejectOffer"
                    /></v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="jobApplicationStore.showCurrentStatusScreen('Offer Accepted')"
          >
            <div>
              <v-card-text class="pa-2">
                <div class="d-flex justify-center">
                  <div>
                    <h2 class="font-weight-regular text-center ma-3 mb-0">
                      Fantastic news!🥳 The offer has been accepted. Add Joining
                      Details
                    </h2>

                    <div class="mx-auto w-50">
                      <v-form ref="refAddPlacementForm">
                        <div>
                          <VueDatePicker
                            v-model="dateTime"
                            :is-24="false"
                            dark
                            :clearable="false"
                            :enable-time-picker="false"
                            class="mt-2"
                            teleport-center
                            month-name-format="long"
                            ><template #dp-input>
                              <CustomTextFieldComponent
                                type="text"
                                hide-details="auto"
                                readonly
                                :model-value="convertDate"
                                prepend-inner-icon="mdi-calendar"
                                :rules="[requiredValidator]"
                                class="pb-0"
                                label="Select Date"
                              >
                              </CustomTextFieldComponent>
                            </template>
                          </VueDatePicker>
                          <v-textarea
                            label="Comment(Optional)"
                            counter="30"
                            rows="2"
                            @input="
                              comment = $event.target.value.replace(/\s+/g, ' ')
                            "
                            color="#b2d250"
                            variant="outlined"
                            class="mt-3"
                            v-model="comment"
                          />
                        </div>
                      </v-form>
                    </div>

                    <div class="d-flex justify-center">
                      <v-btn
                        color="green"
                        variant="tonal"
                        @click="confirmAddPlacement()"
                        >Add Joining
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </div>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Placement Initiated')
            "
          >
            <v-card-text class="pa-2">
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2">
                    Great job!👏 Your candidate has been successfully placed
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2">
                    Congratulations on this achievement
                  </h3>

                  <div class="d-flex justify-center ma-4">
                    <h4 class="font-weight-regular align-self-center me-2">
                      Click here to view your all placements
                    </h4>
                    <v-btn
                      color="blue"
                      variant="tonal"
                      class="me-2"
                      size="small"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                    <h4 class="font-weight-regular align-self-center me-2">
                      Or
                    </h4>
                    <v-btn
                      color="grey"
                      variant="tonal"
                      class="me-2"
                      size="small"
                      >More Options
                      <template #append>
                        <v-icon size="large">mdi-menu-down</v-icon>
                      </template>
                      <v-menu activator="parent" location="bottom">
                        <v-list>
                          <v-hover v-slot="{ isHovering, props }">
                            <v-list-item class="user-list-name" v-bind="props">
                              <v-chip
                                :variant="isHovering ? 'tonal' : 'text'"
                                class="w-100 rounded"
                                size="large"
                                :style="isHovering ? `cursor: pointer` : ''"
                              >
                                <div class="d-flex">
                                  <v-icon
                                    class="align-self-center me-2"
                                    size="small"
                                    >mdi-check-outline</v-icon
                                  >
                                  <p
                                    class="text-button text-capitalize"
                                    block
                                    flat
                                    variant="text"
                                  >
                                    Join
                                  </p>
                                </div>
                              </v-chip>
                              <JoinDialog
                                :data="{
                                  jobApplicationId:
                                    jobApplicationStore.jobApplication.id,
                                  clientId:
                                    jobApplicationStore.jobApplication.job
                                      .client.id,
                                }"
                              />
                            </v-list-item>
                          </v-hover>

                          <v-hover v-slot="{ isHovering, props }">
                            <v-list-item class="user-list-name" v-bind="props">
                              <v-chip
                                :variant="isHovering ? 'tonal' : 'text'"
                                class="w-100 rounded"
                                size="large"
                                :style="isHovering ? `cursor: pointer` : ''"
                                @click="
                                  slectedJobApplicationId =
                                    jobApplicationStore.jobApplication.id
                                "
                              >
                                <div class="d-flex">
                                  <v-icon
                                    class="align-self-center me-2"
                                    size="small"
                                    >mdi-calendar</v-icon
                                  >
                                  <p
                                    class="text-button text-capitalize"
                                    block
                                    flat
                                    variant="text"
                                  >
                                    Withdraw
                                  </p>
                                </div>
                              </v-chip>
                              <RejectReasonDialog
                                title="Withdraw"
                                @submit="WithdrawPlacement"
                              />
                            </v-list-item>
                          </v-hover>

                          <v-hover v-slot="{ isHovering, props }">
                            <v-list-item class="user-list-name" v-bind="props">
                              <v-chip
                                :variant="isHovering ? 'tonal' : 'text'"
                                class="w-100 rounded"
                                size="large"
                                :style="isHovering ? `cursor: pointer` : ''"
                                @click="
                                  slectedJobApplicationId =
                                    jobApplicationStore.jobApplication.id
                                "
                              >
                                <div class="d-flex">
                                  <v-icon
                                    class="align-self-center me-2"
                                    size="small"
                                    >mdi-close-outline</v-icon
                                  >
                                  <p
                                    class="text-button text-capitalize"
                                    block
                                    flat
                                    variant="text"
                                  >
                                    Reject
                                  </p>
                                </div>
                              </v-chip>
                              <RejectReasonDialog
                                title="Reject"
                                @submit="rejectPlacement"
                              />
                            </v-list-item>
                          </v-hover>

                          <v-hover v-slot="{ isHovering, props }">
                            <v-list-item class="user-list-name" v-bind="props">
                              <v-chip
                                :variant="isHovering ? 'tonal' : 'text'"
                                class="w-100 rounded"
                                size="large"
                                :style="isHovering ? `cursor: pointer` : ''"
                                @click="
                                  slectedJobApplicationId =
                                    jobApplicationStore.jobApplication.id
                                "
                              >
                                <div class="d-flex">
                                  <v-icon
                                    class="align-self-center me-2"
                                    size="small"
                                    >mdi-cancel</v-icon
                                  >
                                  <p
                                    class="text-button text-capitalize"
                                    block
                                    flat
                                    variant="text"
                                  >
                                    Hold
                                  </p>
                                </div>
                              </v-chip>
                              <RejectReasonDialog
                                title="Hold"
                                @submit="holdPlacement"
                              />
                            </v-list-item>
                          </v-hover>
                        </v-list>
                      </v-menu>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Placement Completed')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-3">
                    Great job!👏 Your candidate has been successfully placed
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2 mb-3">
                    Congratulations on this achievement
                  </h3>
                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Placement On Hold')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    Our candidate is currently
                    <span class="text-yellow">on hold</span> in the job
                    application process.
                  </h2>

                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Placement Withdrawn')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    The Placement has been Withdrawn
                  </h2>

                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>

          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Placement Rejected')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    The Placement has been
                    <span class="text-red">rejected</span>
                  </h2>

                  <div class="d-flex justify-center ma-10">
                    <h2 class="font-weight-regular text-center ma-2 mb-5"></h2>
                    <v-btn
                      color="blue"
                      variant="outlined"
                      class="yesButtonStyle me-8"
                      @click="placementPage()"
                      >Placements
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <!-- offer  -->
          <div
            transition="scroll-x-transition"
            v-if="
              jobApplicationStore.showCurrentStatusScreen('Placement Completed')
            "
          >
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h2 class="font-weight-regular text-center ma-2 mb-5">
                    Congratulations!🎉 Well done on reaching this milestone.
                  </h2>
                  <h3 class="font-weight-regular text-center ma-2 mb-5">
                    Great job!👏 Your candidate has been successfully placed
                  </h3>
                </div>
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  color="yellow"
                  variant="outlined"
                  class="yesButtonStyle me-8"
                  @click="dialog = true"
                  >withdrawn
                  <RejectReasonDialog
                    title="Withdraw"
                    @submit="WithdrawPlacement"
                  />
                </v-btn>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>

        <VWindowItem>
          <div transition="scroll-x-transition">
            <v-card-text>
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center ma-3">
                    {{
                      `Dear ${
                        jobApplicationStore.jobApplication?.recruiter.name?.split(
                          " "
                        )[0]
                      }, Current Status of application is`
                    }}
                    <span
                      :class="`text-${jobApplicationsStore.applicationStatusColor(
                        jobApplicationStore.jobApplication?.status,
                        jobApplicationStore.jobApplication?.currentHiringStage,
                        jobApplicationStore.jobApplication?.currentHiringStatus
                      )}`"
                    >
                      {{
                        jobApplicationStore.jobApplication?.currentHiringStatus
                          .toLocaleLowerCase()
                          .includes("lapsed")
                          ? "System Reject"
                          : jobApplicationStore.jobApplication
                              ?.currentHiringStatus
                      }}
                    </span>
                  </h3>
                </div>
              </div>
            </v-card-text>
          </div>
        </VWindowItem>
      </VWindow>
    </VCardText>

    <div
      v-if="jobApplicationStore.showApprovalPendingBluerScreen()"
      class="pendingApproval"
    >
      <div class="pendingContainer">
        <v-icon size="70" color="grey-lighten-2">mdi-lock-outline</v-icon>
        <h3 class="text-font-regular text-gery-lighten-2 mt-2">
          {{ jobApplicationStore.showMessageOnBlurScreen() }}
        </h3>
        <div
          class="d-flex justify-center mt-4"
          v-if="jobApplicationStore.showApprovePendingButtons()"
        >
          <v-btn
            variant="flat"
            color="#b2d250"
            :disabled="jobApplicationStore.isApproveButtonDisabled()"
            rounded="lg"
            class="me-4"
            >Approve

            <CommentDialog
              :clientId="jobApplicationStore.jobApplication.job.client.id"
              title="Approve Job Application"
              option="optional"
              @approve-jobApplication="approvedJobApplication"
            />
          </v-btn>
          <v-btn variant="flat" color="red" rounded="lg"
            >Reject
            <CommentDialog
              title="Reject Job Application"
              @reject-jobApplication="rejectJobApplication"
            />
          </v-btn>
        </div>
      </div>
    </div>
  </VCard>

  <LoaderDialog :model-value="jobApplicationStore.isLoading" />
  <SelectTierTypeDialog />
  <ConfirmationDialoge
    v-model="applicationSharedConfirmDialog"
    @action-yes="applicationShared()"
    @update-model="applicationSharedConfirmDialog = false"
    title="Application Shared"
    text="Are you sure you have Shared The Application?"
  />
  <ConfirmationDialoge
    v-model="shortlistJobApplicationDialog"
    @action-yes="onShortlisted()"
    @update-model="shortlistJobApplicationDialog = false"
    title="Shortlisted"
    text="Are you sure you want to Shortlist? "
  />
  <ConfirmationDialoge
    v-model="addPlacementDialog"
    @action-yes="addPlacement()"
    @update-model="addPlacementDialog = false"
    title="Add Joining"
    text="Are you sure you want to Add Joining?"
  />
  <ConfirmationDialoge
    v-model="confirmChangeJobApplicationDialog"
    @action-yes="OnConfirmChangeJobApplicationStatus()"
    @update-model="confirmChangeJobApplicationDialog = false"
    :title="selectedStatus?.name"
    :text="`Are you sure ${selectedStatus?.name}?`"
  />
</template>

<style lang="scss" scoped>
.pendingApproval {
  background-color: rgb(255 255 255/0.025);
  backdrop-filter: blur(5px);
  border-radius: 5px;
  font-family: sans-serif;
  text-align: center;
  padding: 20px 40px;
  height: 110vh;
  width: 200%;
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.025);
}

.pendingContainer {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  font-family: sans-serif;
}

@media screen and (max-width: 1366px) {
  .pendingApproval {
    top: 70%;
    left: 10%;
    transform: translate(-50%, -50%);
  }
  .pendingContainer {
    top: 30%;
    left: 70%;
    transform: translate(-50%, -50%);
  }
}
</style>
