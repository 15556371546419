<template>
  <v-dialog v-model="dialog" activator="parent" width="600">
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="dialog = false" />
      <v-card-text class="pb-0 pa-4">
        <h3 class="font-weight-regular text-center mb-4">Closure</h3>

        <v-form ref="refForm">
          <v-table density="compact">
            <tbody>
              <tr>
                <td><h4 class="font-weight-regular">Candidate Name</h4></td>
                <td>
                  <h4 class="font-weight-regular">
                    : {{ candidateStore.formattedCandidate.candidateName }}
                  </h4>
                </td>
              </tr>
              <tr>
                <td><h4 class="font-weight-regular">Candidate Number</h4></td>
                <td>
                  <h4 class="font-weight-regular">
                    : {{ candidateStore.formattedCandidate.candidateNumber }}
                  </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="font-weight-regular">Designation</h4>
                </td>
                <td>
                  <h4 class="font-weight-regular">
                    : {{ candidateStore.formattedCandidate.jobTitle }}
                  </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="font-weight-regular">CTC Amount</h4>
                </td>
                <td class="align-self-center">
                  <div class="d-flex w-50 pt-2 pb-2">
                    <span>:</span>
                    <CustomTextFieldComponent
                      hide-details="auto"
                      v-model="salary"
                      label="Salary"
                      type="text"
                      inputmode="numeric"
                      @input="salary = $event.target.value.replace(/\s+/g, ' ')"
                      v-maska:[vMaskOptions]
                      :maxlength="10"
                      :rules="[requiredValidator, lengthOfSsalary]"
                      active="#B2D250"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="font-weight-regular">Recruitement fees</h4>
                </td>
                <td>
                  <div class="d-flex w-50 pt-2 pb-2">
                    <span>:</span>
                    <CustomTextFieldComponent
                      v-model="recruitementFees"
                      hide-details="auto"
                      :rules="[requiredValidator]"
                      label="Fee"
                      active="#B2D250"
                    ></CustomTextFieldComponent>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="font-weight-regular">Billing Amount</h4>
                </td>
                <td>
                  <div class="d-flex w-50 pt-2 pb-2">
                    <span>:</span>
                    <CustomTextFieldComponent
                      hide-details="auto"
                      v-model="billingAmount"
                      label="Billing Amount"
                      type="text"
                      inputmode="numeric"
                      @input="
                        billingAmount = $event.target.value.replace(/\s+/g, ' ')
                      "
                      v-maska:[vMaskOptions]
                      :maxlength="10"
                      :rules="[requiredValidator, lengthOfSsalary]"
                      active="#B2D250"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="font-weight-regular">Date of Joining</h4>
                </td>
                <td>
                  <div class="d-flex pt-2 pb-2">
                    <span>:</span>
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      class=""
                    >
                      <template v-slot:activator="{ props }">
                        <CustomTextFieldComponent
                          v-model="convertDateOfJoiningDate"
                          label="Joining Date"
                          append-icon="event"
                          class="w-100"
                          hide-details="auto"
                          :rules="[requiredValidator]"
                          readonly
                          prepend-inner-icon="mdi-calendar-month-outline"
                          v-bind="props"
                        />
                      </template>
                      <DatePicker
                        v-model="dateOfJoining"
                        borderless
                        mode="date"
                        class="bg-grey-darken-4 rounded-xl w-100"
                        title-position="left"
                        @dayclick="menu = false"
                        color="green"
                        is-dark="{ selector: ':root', darkClass: 'dark' }"
                      />
                    </v-menu>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="font-weight-regular">Recruiter Name</h4>
                </td>
                <td>
                  <h4 class="font-weight-regular">
                    : {{ candidateStore.formattedCandidate.recruiterName }}
                  </h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="font-weight-regular">Client Name</h4>
                </td>
                <td>
                  <h4 class="font-weight-regular">
                    : {{ candidateStore.formattedCandidate.clientName }}
                  </h4>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onCopy()" variant="tonal" color="#74a942"> Copy </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmationDialog"
    @action-yes="updateClosure()"
    @update-model="confirmationDialog = false"
    title="Update Closure"
    text="Want to Update Closure?"
  />
</template>
  
<script setup>
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import { useCandidateStore } from "@/store/Candidate/CandidateStore";
import { ref } from "vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import { DatePicker } from "v-calendar";
import { toast } from "vue3-toastify";
import { vMaska } from "maska";
import { watch } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { computed } from "vue";
import { getDDMMYYYYFormat } from "@/composables/DateTime";
import { inject } from "vue";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "@/firebase";
const vMaskOptions = {
  mask: "99,99,99#",
  tokens: {
    9: { pattern: /[0-9]/, repeated: true },
  },
  reversed: true,
};
const $cookies = inject("$cookies");
const user = $cookies.get("user");
// const closureStore = useClosureStore();
const candidateStore = useCandidateStore();
const dialog = ref(false);
const menu = ref(false);
const confirmationDialog = ref(false);
const refForm = ref();
const salary = ref(null);
const recruitementFees = ref(null);
const billingAmount = ref(null);
const dateOfJoining = ref(null);
// const feeType = ref(null);
const percentage = ref(null);
const amount = ref(null);
// const comment = ref();

const convertDateOfJoiningDate = computed(() => {
  return getDDMMYYYYFormat(dateOfJoining.value) === "Invalid date" ||
    getDDMMYYYYFormat(dateOfJoining.value) === "01/01/1970"
    ? ""
    : getDDMMYYYYFormat(dateOfJoining.value);
});
const onCopy = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      const formattedText = `Candidate name - ${
        candidateStore.formattedCandidate.candidateName
      }
Contact - ${candidateStore.formattedCandidate.candidateNumber}
Designation - ${candidateStore.formattedCandidate.jobTitle}
CTC Amount - ${salary.value ? salary.value : "N/A"} 
Billing Amount - ${billingAmount.value ? billingAmount.value : "N/A"}
DOJ - ${convertDateOfJoiningDate.value ? convertDateOfJoiningDate.value : "N/A"}
Recruitment fees - ${recruitementFees.value ? recruitementFees.value : "N/A"}
Recruiter - ${candidateStore.formattedCandidate.recruiterName}
Client Name - ${candidateStore.formattedCandidate.clientName}`;
      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          toast.success("Copied to Clipboard!", {
            autoClose: 3000,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
        })
        .catch((err) => {
          console.error("Failed to copy!", err);
        });

      const closureData = {
        candidateName: candidateStore.formattedCandidate.candidateName,
        candidateNumber: candidateStore.formattedCandidate.candidateNumber,
        jobTitle: candidateStore.formattedCandidate.jobTitle,
        salary: salary.value,
        billingAmount: billingAmount.value,
        dateOfJoining: convertDateOfJoiningDate.value,
        recruitementFees: recruitementFees.value,
        recruiterName: candidateStore.formattedCandidate.recruiterName,
        clientName: candidateStore.formattedCandidate.clientName,
        userId: user?.id,
        userName: user?.name,
        jobApplicationId: candidateStore.formattedCandidate.jobApplicationId,
        timestamp: serverTimestamp(),
      };
      addDoc(collection(db, "ClosureClickTracks"), closureData);
    }
  });
};

watch(
  () => [percentage.value, salary.value],
  () => {
    amount.value =
      (Number(salary.value?.split(",").join("")) / 100) * percentage.value;
  }
);
watch(
  () => salary.value,
  () => {
    candidateStore.formattedCandidate.salary = salary.value;
  }
);
watch(
  () => billingAmount.value,
  () => {
    candidateStore.formattedCandidate.billingAmount = billingAmount.value;
  }
);
watch(
  () => recruitementFees.value,
  () => {
    candidateStore.formattedCandidate.recruitementFees = recruitementFees.value;
  }
);
watch(
  () => convertDateOfJoiningDate.value,
  () => {
    candidateStore.formattedCandidate.dateOfJoining =
      convertDateOfJoiningDate.value;
  }
);
</script>
  