<template>
  <div class="pa-4">
    <div class="d-flex justify-center">
      <div>
        <div class="d-flex justify-center pa-1" v-if="props.level === 'L3'">
          <div>
            <h2 class="font-weight-regular text-center ma-3 mb-0">
              kindly confirm if the candidate has been selected for the job
            </h2>
            <h3 class="font-weight-regular text-center ma-2">
              Looking forward to sharing the offer details. Thank you!
            </h3>
            <div class="d-flex justify-center">
              <v-btn
                color="orange"
                variant="tonal"
                @click="selectedApplicationDialog = true"
                >Selected
              </v-btn>
            </div>
          </div>
        </div>
        <div class="d-flex justify-center" v-else>
          <v-card
            border
            color="transparent"
            width="400"
            class="me-3"
            elevation="4"
          >
            <v-card-text class="pa-1">
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center">
                    Should we schedule
                    {{ props.level === "L1" ? "Level 2" : "Level 3" }}
                    interview for this candidate?
                  </h3>
                  <h4 class="font-weight-regular text-center ma-2">
                    Click here to arrange the interview.
                  </h4>
                  <div class="d-flex justify-center">
                    <v-btn variant="tonal" color="green">
                      Schedule
                      <v-dialog
                        v-model="scheduleInterviewDialog"
                        activator="parent"
                        width="600"
                      >
                        <v-card color="#303030" rounded="lg">
                          <v-card-text class="pa-4">
                            <h3 class="font-weight-regular text-center mb-4">
                              Schedule Interview
                            </h3>
                            <DialogCloseBtn
                              @close-dialog="scheduleInterviewDialog = false"
                            />
                            <InterviewScheduleForm
                              :level="props.level == 'L1' ? 'L2' : 'L3'"
                            />
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <div class="align-self-center me-3">
            <h3 class="font-weight-regular mx-auto">Or</h3>
          </div>
          <v-card border color="transparent" width="400" elevation="4">
            <v-card-text class="pa-1">
              <div class="d-flex justify-center">
                <div>
                  <h3 class="font-weight-regular text-center">
                    Please confirm if the candidate is selected for the job.
                  </h3>
                  <h4 class="font-weight-regular text-center ma-2">
                    click here to proceed with the next steps.
                  </h4>

                  <div class="d-flex justify-center">
                    <v-btn
                      class="mx-auto"
                      style="align-self: center"
                      color="orange"
                      variant="tonal"
                      @click="selectedApplicationDialog = true"
                      >Selected
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>

  <ConfirmationDialoge
    v-model="selectedApplicationDialog"
    @action-yes="selectedApplication()"
    @update-model="selectedApplicationDialog = false"
    title="Selection"
    text="Are you sure on Selection?"
  />
</template>

<script setup>
import InterviewScheduleForm from "@/components/JobAplication/components/InterviewScheduleForm.vue";
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { defineProps, ref } from "vue";

const props = defineProps(["level"]);
const jobApplicationStore = useJobApplicationStore();
const selectedApplicationDialog = ref(false);
const scheduleInterviewDialog = ref(false);
// selection
const selectedApplication = async () => {
  selectedApplicationDialog.value = false;
  let comment = {
    comment: "Candidate Selected",
  };
  await jobApplicationStore.selectedApplication(comment);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
</script>
