import { defineStore } from "pinia";
import api from "@/services/api";
import { toast } from "vue3-toastify";
import { getDataFromLocalStorage } from "@/composables/LocalStorage";
import { useOverviewStore } from "../Overview/OverviewStore";
import { useUserAttendanceStore } from "./UserStore";
import { useJobApplicationsStore } from "../JobApplications/JobApplicationsStore";

export const useUsersStore = defineStore("usersStore", {
  state: () => ({
    overviewStore: useOverviewStore(),
    userAttendanceStore: useUserAttendanceStore(),
    jobApplicationsStore: useJobApplicationsStore(),
    isActive: true,
    isLoading: false,
    confirmDialog: false,
    successDialog: false,
    isValideUsername: true,
    updateUserDialog: false,
    isAddUerFormDrawerOpen: false,
    users: [],
    searchUsers: [],
    targets: [],
    bankAccounts: [],
    applicationEditors: [],
    userDetails: {},
    salaryStructure: null,
    allSalaryStructures: null,
    searchUser: "",
    selectedDepartment: null,
    selectedRole: null,
    year: new Date().getFullYear(),
  }),
  actions: {
    async getUsers() {
      this.setIsLoading(true);
      await api
        .get(this.getUserURL, {})
        .then((v) => {
          this.users = v.data;
          this.setIsLoading(false);
        })
        .catch((err) => {
          this.setIsLoading(false);
          return err;
        });
    },
    async getActiveUsers() {
      this.isLoading = true;
      await api
        .get("/users/active", {})
        .then((v) => {
          this.users = v.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getSearchUsers() {
      this.isLoading = true;
      await api
        .get("/users/search", {})
        .then((v) => {
          this.searchUsers = v.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addUser(user) {
      this.setIsLoading(true);
      await api
        .post("/users", user)
        .then((res) => {
          if (res.status === 200) {
            toast.success("User Added Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.setIsLoading(false);
        })
        .catch((err) => {
          this.setIsLoading(false);
          return err;
        });
    },
    toggleAddUserFormDrawer(value) {
      this.isAddUerFormDrawerOpen = value;
    },
    setIsLoading(value) {
      this.isLoading = value;
    },

    async checkUsername(username) {
      this.setIsLoading(true);
      await api
        .get(`/users/check-username/${username}`, {})
        .then((v) => {
          this.isValideUsername = v.data;
          this.setIsLoading(false);
        })
        .catch((err) => {
          this.setIsLoading(false);
          return err;
        });
    },
    async getUser(userId) {
      this.setIsLoading(true);
      await api
        .get(`/users/${userId}`, {})
        .then((res) => {
          this.userDetails = res.data;
          this.setIsLoading(false);
        })
        .catch((err) => {
          this.setIsLoading(false);
          return err;
        });
    },
    async getUserProfile(userId) {
      this.setIsLoading(true);
      await api
        .get(`/users/${userId}/profile`, {})
        .then((res) => {
          this.userDetails = res.data;
          this.bankAccounts = res.data.bankAccounts;
          this.applicationEditors = res.data.applicationEditors;
          this.salaryStructure = res.data.salaryStructure;

          this.setIsLoading(false);
        })
        .catch((err) => {
          this.setIsLoading(false);
          return err;
        });
    },
    async activateDeactivateUser() {
      this.setIsLoading(true);
      const url = this.getUserActiveStatusURL(
        this.userDetails.active,
        this.userDetails.id
      );
      await api
        .put(url)
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.userDetails.active = !this.userDetails.active;
          this.setIsLoading(false);
          return this.userDetails;
        })
        .catch((err) => {
          this.setIsLoading(false);
          return err;
        });
    },
    async updateUser(userData) {
      this.setIsLoading(true);
      await api
        .put(`/users/${this.userDetails.id}`, userData)
        .then((res) => {
          this.userDetails = res.data;
          toast.success("User Updated Successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.setIsLoading(false);
        })
        .catch((err) => {
          this.setIsLoading(false);
          return err;
        });
    },
    async resetPassword() {
      this.setIsLoading(true);
      await api
        .post(`/users/${this.userDetails.id}/reset-password`)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.setIsLoading(false);
        })
        .catch((err) => {
          this.setIsLoading(false);
          return err;
        });
    },
    async addRolePermissionToUser(selectedPermissions) {
      this.isLoading = true;
      await api
        .post(`/users/${this.userDetails.id}/permissions`, {
          permissionIds: selectedPermissions,
        })
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateSenior(senior) {
      this.isLoading = true;
      await api
        .put(`/users/${this.userDetails.id}/senior/${senior.id}`)
        .then((v) => {
          this.userDetails.senior = senior;
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addEditor(editorId) {
      this.isLoading = true;
      await api
        .put(`/users/${this.userDetails.id}/editor/${editorId}`)
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async removeEditor(editorId) {
      this.isLoading = true;
      await api
        .delete(`/users/${this.userDetails.id}/editor/${editorId}`)
        .then((v) => {
          toast.success(v.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    //bank
    async addBankDetails(bankDetails) {
      this.isLoading = true;
      await api
        .post(`/users/${this.userDetails.id}/bank-accounts`, bankDetails)
        .then((res) => {
          this.bankAccounts.push(res.data);
          toast.success("Bank Added Successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getBankAccounts() {
      this.isLoading = true;
      await api
        .get(`/users/${this.userDetails.id}/bank-accounts`)
        .then((res) => {
          this.bankAccounts = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateBankAccount(bankAccountId, bankDetails) {
      this.isLoading = true;
      await api
        .put(
          `/users/${this.userDetails.id}/bank-accounts/${bankAccountId}`,
          bankDetails
        )
        .then((res) => {
          console.log(res.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async setPrimaryBankAccount(bankAccountId) {
      this.isLoading = true;
      await api
        .put(
          `/users/${this.userDetails.id}/bank-accounts/${bankAccountId}/primary`
        )
        .then((res) => {
          console.log(res.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    //salary
    async addSalaryStructure(salaryStructure) {
      this.isLoading = true;
      await api
        .post(
          `/users/${this.userDetails.id}/salary-structures`,
          salaryStructure
        )
        .then((res) => {
          this.salaryStructure = res.data;
          toast.success("Salary Structure Added Successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getSalaryStructure() {
      this.isLoading = true;
      await api
        .get(`/users/${this.userDetails.id}/salary-structures`)
        .then((res) => {
          this.allSalaryStructures = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateSalaryStructure(salaryStructureId, salaryStructure) {
      this.isLoading = true;
      await api
        .put(
          `/users/${this.userDetails.id}/salary-structures/${salaryStructureId}`,
          salaryStructure
        )
        .then((res) => {
          console.log(res.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addPreviousSalaryStructure(salaryStructure) {
      this.isLoading = true;
      await api
        .post(
          `/users/${this.userDetails.id}/salary-structures/create-previous`,
          salaryStructure
        )
        .then((res) => {
          console.log(res.data);
          toast.success("Salary Structure Added Successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addAppraisalSalaryStructure(salaryStructureId, salaryStructure) {
      this.isLoading = true;
      await api
        .post(
          `/users/${this.userDetails.id}/salary-structures/${salaryStructureId}/increment/fixed`,
          salaryStructure
        )
        .then((res) => {
          console.log(res.data);
          toast.success("Increment updated Successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    // target
    async addTarget(userId, data) {
      this.isLoading = true;
      await api
        .post(`/recruitment-targets/create-target/${userId}`, data)
        .then((res) => {
          this.targets.push(res.data);
          toast.success("Target Added", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addMonthTarget(userId, data) {
      this.isLoading = true;
      await api
        .post(
          `/recruitment-targets/create-target/${userId}/month/${data.month}/year/${data.year}`
        )
        .then((res) => {
          this.targets.push(res.data);
          toast.success("Target Added", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getTarget(userId) {
      this.isLoading = true;
      await api
        .get(`/recruitment-targets/user/${userId}/year/${this.year}`)
        .then((res) => {
          this.targets = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateTarget(targetId, data) {
      this.isLoading = true;
      await api
        .put(`/recruitment-targets/${targetId}`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async achievedTarget(targetId, data) {
      this.isLoading = true;
      await api
        .put(`/recruitment-targets/${targetId}/achieved`, data)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async createTargets(userId) {
      this.isLoading = true;
      await api
        .post(`/recruitment-targets/create-targets/${userId}`)
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    toggleActiveStatus() {
      this.isActive = !this.isActive;
      this.getUsers();
    },
    getUserActiveStatusURL(value, userId) {
      return `/users/${userId}/${value ? "deactivate" : "activate"}`;
    },
    refreshActiveUsers() {
      this.getActiveUsers();
    },
    onMonthChange(tab, month, userId) {
      if (tab == 0) {
        this.overviewStore.month = month;
        this.year = month.year;
        this.overviewStore.getUserOverview(userId);
      } else if (tab == 1) {
        this.userAttendanceStore.onDateRangeChanged(userId, month);
      } else if (tab === 2) {
        this.jobApplicationsStore.month = month;
        this.jobApplicationsStore.userId = userId;
        this.jobApplicationsStore.getJobApplicationsByDateRange();
      }
    },
    createAllUsersTargets() {
      const userIds = this.getUserIds;
      userIds.forEach(async (user) => {
        await this.createTargets(user);
        this.getTarget(user);
      });
    },
    createAllUsersMonthlyTargets() {
      const userIds = this.getUserIds;
      userIds.forEach(async (user) => {
        let data = {
          month: 1,
          year: 2025,
        };
        await this.addMonthTarget(user, data);
        this.getTarget(user);
      });
    },
  },
  getters: {
    getUserURL() {
      if (this.isActive) {
        return "/users/active";
      } else {
        return "/users/in-active";
      }
    },
    getFormattedUserList() {
      const searchItem = this.searchUser?.toLowerCase();
      if (this.selectedDepartment && this.selectedRole) {
        return this.users?.filter(
          (user) =>
            user?.department === this.selectedDepartment &&
            user?.role === this.selectedRole
        );
      }
      if (this.selectedDepartment !== null) {
        return this.users?.filter(
          (user) => user?.department === this.selectedDepartment
        );
      } else if (this.selectedRole !== null) {
        return this.users?.filter((user) => user?.role === this.selectedRole);
      } else if (searchItem !== "") {
        return this.users?.filter(
          (user) =>
            user?.fullName.toLowerCase().includes(searchItem) ||
            user?.username.includes(searchItem)
          // user?.email.includes(searchItem)
        );
      }
      return this.users;
    },
    getSortedTargetData() {
      const sortByMonth = (a, b) => a.month - b.month;
      const sortedArray = this.targets.sort(sortByMonth);
      return sortedArray;
    },
    getDepartments() {
      const departments = this.users?.map((user) => user?.department);
      return [...new Set(departments)];
    },
    getRoles() {
      const role = this.users?.map((user) => user?.role);
      return [...new Set(role)];
    },
    getDepartmentsFromLocalStorage() {
      const departments = getDataFromLocalStorage("departments");
      return departments?.map((department) => department.name);
    },
    getDesignationsFromLocalStorage() {
      const designations = getDataFromLocalStorage("designations");
      return designations?.map((designation) => designation.name);
    },
    getRolesFromLocalStorage() {
      const rolesName = getDataFromLocalStorage("roles");
      return rolesName
        ?.map((role) => role.name)
        .filter(
          (role) => role.name != "SUPER_USER" && role.name != "SUPER_ADMIN"
        );
    },
    getUserIds() {
      return this.users?.map((user) => user?.id);
    },
    getAscendingSalaryStructures() {
      return this.allSalaryStructures
        ?.sort((a, b) => new Date(b.effectiveFrom) - new Date(a.effectiveFrom))
        .map((salaryStructure, i) => ({
          salaryStructure: salaryStructure,
          title:
            i === 0
              ? "Active"
              : i === this.allSalaryStructures?.length - 1
              ? "Joining Salary"
              : `Appraisal ${this.allSalaryStructures?.length - i - 1}`,
        }));
    },
  },
});
