<template>
  <v-dialog v-model="dialog" activator="parent" max-width="400">
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="dialog = false" />
      <v-card-text class="pb-0 pa-4">
        <h3 class="font-weight-regular text-center mb-4">Update Date</h3>
        <v-form ref="refForm">
          <v-row>
            <v-col cols="12">
              <VueDatePicker
                v-model="date"
                :clearable="false"
                dark
                auto-apply
                teleport-center
                month-name-format="long"
                ><template #dp-input>
                  <CustomTextFieldComponent
                    type="text"
                    hide-details="auto"
                    readonly
                    :model-value="convertInterviewDate"
                    prepend-inner-icon="mdi-calendar"
                    :rules="[requiredValidator]"
                    class="pb-0"
                    label="Select Date"
                  >
                  </CustomTextFieldComponent>
                </template>
              </VueDatePicker>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Comment"
                rows="3"
                color="#b2d250"
                variant="outlined"
                @input="comment = $event.target.value.replace(/\s+/g, ' ')"
                counter="30"
                v-model="comment"
                :rules="[requiredValidator, commentLengthValidator]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onSubmit()" variant="tonal" color="#74a942"> Submit </v-btn>
        <v-btn @click="dialog = false" variant="tonal" color="red"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="rescheduleInterview()"
    @update-model="confirmDialogStage = false"
    title="Update Date of application"
    text="Are you sure you want to Update Date of application?"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { computed, ref } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { getYYYYMMDDFormat } from "@/composables/DateTime";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { commentLengthValidator } from "@/composables/Validators";
const jobApplicationStore = useJobApplicationStore();
const confirmDialogStage = ref(false);
const refForm = ref();
const date = ref();

const convertInterviewDate = computed(() => {
  return getYYYYMMDDFormat(date.value) === "Invalid date" ||
    getYYYYMMDDFormat(date.value).includes("1970-01-01")
    ? ""
    : getYYYYMMDDFormat(date.value);
});
const comment = ref("");
const dialog = ref(false);

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialogStage.value = true;
    }
  });
};

const rescheduleInterview = async () => {
  let data = {
    date: convertInterviewDate.value,
    comment: comment.value,
  };
  confirmDialogStage.value = false;
  dialog.value = false;
  jobApplicationStore.updateJobApplicationDate(data);
};
</script>
