<template>
  <v-dialog
    v-model="jobApplicationStore.confirmUpdateRsume"
    persistent
    max-width="400"
    transition="dialog-top-transition"
  >
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="jobApplicationStore.confirmUpdateRsume = false" />
      <v-card-text class="pb-0 pa-4">
        <h3 class="font-weight-regular text-center mb-2">Update Resume</h3>
        <div>
          <h4 class="font-weight-regular text-center">Kindly upload Resume</h4>
          <v-form ref="refResume">
            <div class="d-flex mt-8">
              <v-file-input
                color="#b2d250"
                v-model="file"
                clearable
                accept=".pdf, .doc, .docx "
                class="me-3"
                label="File input"
                :rules="[requiredValidator]"
                placeholder="Select your file"
                prepend-icon="mdi-paperclip"
                variant="outlined"
                :show-size="1000"
                multiple
              >
              </v-file-input>
            </div>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onSubmit()" variant="tonal" color="#74a942"> Submit </v-btn>
        <v-btn
          @click="jobApplicationStore.confirmUpdateRsume = false"
          variant="tonal"
          color="red"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmationDialog"
    @action-yes="updateResume()"
    @update-model="confirmationDialog = false"
    title="Update Resume"
    text="Are you sure you want to Update Resume ?"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { ref } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { toast } from "vue3-toastify";
import { formatFileName } from "@/composables/Formatters";

const jobApplicationStore = useJobApplicationStore();
const confirmationDialog = ref(false);
const refResume = ref();
const file = ref(null);

const onSubmit = () => {
  if (file.value) {
    confirmationDialog.value = true;
  } else {
    toast.error("Required File", {
      autoClose: 1500,
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      transition: toast.TRANSITIONS.ZOOM,
    });
  }
};
const updateResume = async () => {
  let resume = file.value ? formatFileName(file.value[0]) : null;
  const formData = new FormData();
  formData.append("resume", resume);
  await jobApplicationStore.updateResume(formData);
  confirmationDialog.value = false;
  jobApplicationStore.confirmUpdateRsume = false;
};
</script>
