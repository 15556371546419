<template>
  <v-sheet :color="props.level == 'L1' ? 'transparent' : '#303030'">
    <v-card-text class="pa-2">
      <v-form ref="refInterviewForm">
        <v-row dense>
          <v-col cols="6">
            <CustomTextFieldComponent
              label="Title"
              @input="title = $event.target.value.replace(/\s+/g, ' ')"
              :rules="[requiredValidator]"
              prepend-inner-icon="mdi-format-title"
              hide-details="auto"
              v-model="title"
            />
          </v-col>
          <v-col cols="6">
            <CustomTextFieldComponent
              label="Description (Optional)"
              v-model="description"
              prepend-inner-icon="mdi-subtitles-outline"
              @input="description = $event.target.value.replace(/\s+/g, ' ')"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="6">
            <VueDatePicker
              v-model="dateTime"
              :is-24="false"
              :clearable="false"
              dark
              :enable-time-picker="false"
              teleport-center
              month-name-format="long"
              ><template #dp-input>
                <CustomTextFieldComponent
                  type="text"
                  hide-details="auto"
                  readonly
                  :model-value="convertInterviewDate"
                  prepend-inner-icon="mdi-calendar"
                  :rules="[requiredValidator]"
                  label="Date"
                >
                </CustomTextFieldComponent>
              </template>
            </VueDatePicker>
          </v-col>
          <v-col cols="6">
            <VueDatePicker
              v-model="time"
              time-picker
              :is-24="false"
              :clearable="false"
              dark
              teleport-center
              month-name-format="long"
              ><template #dp-input>
                <CustomTextFieldComponent
                  type="text"
                  hide-details="auto"
                  readonly
                  :model-value="time ? timeObject(time) : ''"
                  prepend-inner-icon="mdi-clock-outline"
                  :rules="[requiredValidator]"
                  label="Time"
                >
                </CustomTextFieldComponent>
              </template>
            </VueDatePicker>
          </v-col>
          <v-col cols="12">
            <v-file-input
              color="#b2d250"
              v-model="file"
              clearable
              label="Upload Acknowledgement"
              density="compact"
              accept="image/png, image/jpeg, image/jpg"
              :rules="[requiredValidator]"
              placeholder="Select your file"
              rounded="lg"
              variant="outlined"
              :show-size="1000"
              multiple
            >
            </v-file-input>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-center" v-if="props.level === 'L1'">
        <v-btn color="green" variant="tonal" @click="confirmScheduleInterview()"
          >Schedule</v-btn
        >
      </div>
    </v-card-text>
    <v-card-actions class="justify-center" v-if="props.level !== 'L1'">
      <v-btn variant="tonal" color="#74a942" @click="confirmScheduleInterview()"
        >Submit
      </v-btn>
    </v-card-actions>
  </v-sheet>

  <ConfirmationDialoge
    v-model="scheduleConfirmation"
    @action-yes="scheduleInterview()"
    @update-model="scheduleConfirmation = false"
    title="Schedule Interview"
    text="Confirm to Schedule Interview ? "
  />
</template>


<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import { useInterviewStore } from "@/store/JobApplications/InterviewStore";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { getYYYYMMDDFormat, timeObject } from "@/composables/DateTime";
import { requiredValidator } from "@/composables/Validators";
import { formatFileName } from "@/composables/Formatters";
import { ref, computed, defineProps } from "vue";
import moment from "moment-timezone";
const interviewStore = useInterviewStore();
const jobApplicationStore = useJobApplicationStore();
const props = defineProps(["level"]);
const scheduleConfirmation = ref(false);
const refInterviewForm = ref();
const dateTime = ref();
const time = ref(null);
const title = ref("");
const description = ref("");
const file = ref(null);
const convertInterviewDate = computed(() => {
  return getYYYYMMDDFormat(dateTime.value) === "Invalid date" ||
    getYYYYMMDDFormat(dateTime.value).includes("1970-01-01")
    ? ""
    : getYYYYMMDDFormat(dateTime.value);
});
const confirmScheduleInterview = () => {
  refInterviewForm.value?.validate().then((valid) => {
    if (valid.valid) {
      scheduleConfirmation.value = true;
    }
  });
};
const formattedInterviewDateAndTime = computed(() => {
  const date = getYYYYMMDDFormat(dateTime.value);
  const localDateString = moment(
    `${date} ${time.value.hours}:${time.value.minutes}`,
    "YYYY-MM-DD HH:mm"
  )
    .tz("Asia/Kolkata")
    .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  return localDateString;
});
const scheduleInterview = async () => {
  let acknowledgement = file.value ? formatFileName(file.value[0]) : null;
  const formData = new FormData();
  formData.append("title", title.value);
  formData.append("description", description.value);
  formData.append("level", props.level);
  formData.append(
    "dateTime",
    moment(formattedInterviewDateAndTime.value).format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    )
  );
  formData.append(
    "comment",
    `${props.level} Interview Scheduled on the given Date and Time`
  );
  formData.append("acknowledgement", acknowledgement);
  scheduleConfirmation.value = false;
  await interviewStore.scheduleInterview(formData);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
</script>

