<template>
  <v-dialog v-model="dialog" activator="parent" width="400">
    <v-card color="#343435" rounded="lg" max-height="650">
      <DialogCloseBtn @close-dialog="dialog = false" />
      <h3 class="text-center font-weight-regular pt-3">Request To Add Status</h3>

      <v-card-text class="pa-4 pb-0">
        <h5 class="font-weight-regular mb-5">
          <v-icon class="" color="orange">mdi-alert-circle-outline</v-icon>
          Compose an email requesting the addition of a new status in our GMS
        </h5>
        <v-form ref="refForm">
          <v-row no-gutters>
            <v-col cols="12">
              <CustomTextFieldComponent
                :rules="[requiredValidator]"
                label="Title"
                @input="title = $event.target.value.replace(/\s+/g, ' ')"
                v-model="title"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                variant="outlined"
                color="#b2d250"
                counter="30"
                @input="description = $event.target.value.replace(/\s+/g, ' ')"
                hide-details="auto"
                :rules="[requiredValidator, commentLengthValidator]"
                label="Description"
                v-model="description"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center gap-2">
        <v-btn variant="tonal" color="#b2d250" @click="onSubmit()">Mail</v-btn>
        <v-btn variant="tonal" color="red" @click="closeDialog()">Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="onConfirm()"
    @update-model="confirmDialogStage = false"
    title="Add Status"
    :text="`Are you sure you want to Request to Add Status?`"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import DialogCloseBtn from "@/components/SharedComponent/components/DialogCloseBtn.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/components/CustomTextFieldComponent.vue";
import { ref } from "vue";
import { requiredValidator } from "@/composables/Validators";
import { commentLengthValidator } from "@/composables/Validators";
// import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";

// const jobApplicationStore = useJobApplicationStore();
const confirmDialogStage = ref(false);
const dialog = ref(false);
const title = ref("");
const description = ref("");
const refForm = ref();

const onSubmit = () => {
  refForm.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmDialogStage.value = true;
    }
  });
};
const onConfirm = () => {
  let data = {
    title: title.value,
    description: description.value,
  };
  confirmDialogStage.value = false;
  dialog.value = false;

  console.log(data);
  openEmailWindow(data);
  //   jobApplicationStore.requestRollback(data);
};
const openEmailWindow = (data) => {
  const toEmail = "snehal.k@genxhire.in";
  const ccEmails = ["nitin.parab@genxhire.in", "hr@genxhire.in"];
  const subject = "Request to Add Status in GMS";
  const body = `
    Dear Admin,

    I am requesting to add a new status to the GMS. Your prompt assistance on this matter would be greatly appreciated.

    Title: ${data.title}
    Description: ${data.description}

    Thank you.`;
  const encodedToEmail = encodeURIComponent(toEmail);
  const encodedCcEmails = ccEmails.map((email) => encodeURIComponent(email)).join("%2C");

  const mailtoUrl = `mailto:${encodedToEmail}?cc=${encodedCcEmails}&subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  window.open(mailtoUrl, "_blank");
};

const closeDialog = () => {
  dialog.value = false;
};
</script>
