import { defineStore } from "pinia";
import api from "@/services/api";
import { useTeamStore } from "../Team/TeamStore";
import { useJobApplicationsStore } from "../JobApplications/JobApplicationsStore";
import { getAllDaysInMonth, getYYYYMMDDFormat } from "@/composables/DateTime";
import { useUsersStore } from "../User/UsersStore";
// import { startOfQuarter } from "date-fns";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const useOverviewStore = defineStore("overviewStore", {
  state: () => ({
    teamStore: useTeamStore(),
    usersStore: useUsersStore(),
    jobApplicationsStore: useJobApplicationsStore(),
    jobApplicationsDetailsDialog: false,
    isLoading: false,
    selectedMember: "",
    selectedMemberId: "",
    selectedStatus: null,
    selectedNoticePeriodStatus: null,
    overview: {},
    teamMembers: [],
    month: null,
    quarter: null,
    startDate: null,
    endDate: null,
  }),
  actions: {
    async getTeamOverview(teamId) {
      this.isLoading = true;
      await api
        .get(
          `/overview/team/${teamId}?start-date=${getYYYYMMDDFormat(
            this.startDate
          )}&end-date=${getYYYYMMDDFormat(this.endDate)}`
        )
        .then((res) => {
          this.overview = res.data;
          this.teamMembers = res.data.members;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getUserOverview(userId) {
      this.isLoading = true;
      await api
        .get(
          `/overview/recruiter/${userId}?start-date=${getYYYYMMDDFormat(
            this.startDate
          )}&end-date=${getYYYYMMDDFormat(this.endDate)}`
        )
        .then((res) => {
          this.overview = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async viewJobApllicationsByUser(userId) {
      this.selectedMemberId = userId;
      await this.jobApplicationsStore.getJobApplicationsByDateRange();
    },
    getDailyJobApplicationsCount(jobApplications) {
      const datesOfMonth = getAllDaysInMonth(this.month.year, this.month.month);
      const formattedJobApplicationsData = {};

      datesOfMonth.forEach((date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;

        const count = jobApplications
          ?.filter((application) => application.applicationIsApproved)
          .filter(
            (application) =>
              getYYYYMMDDFormat(application.applicationApprovedOn) ==
              formattedDate
          )?.length;
        formattedJobApplicationsData[formattedDate] = {
          date: formattedDate,
          count: count,
        };
      });
      const formattedJobApplicationsDataByDate = Object.values(
        formattedJobApplicationsData
      );
      const data = formattedJobApplicationsDataByDate.filter(
        (application) => application.count > 0
      );
      return data;
    },
    sumOfJobApplications(data) {
      let sum = 0;
      for (let i = 0; i < data.length; i++) sum += data[i].count;
      return sum ? sum : 0;
    },
    async setQuarter(teamId) {
      this.month = null;
      const quarter = Math.floor(this.quarter?.getMonth() / 3) + 1;
      const nextQuarterStart = new Date(
        this.quarter?.getFullYear(),
        quarter * 3,
        1
      );
      const lastDayOfQuarter = new Date(nextQuarterStart - 1);
      let date = {
        start: this.quarter,
        end: lastDayOfQuarter,
      };
      if (date) {
        this.startDate = date.start;
        this.endDate = date.end;
      }
      await this.getTeamOverview(teamId);
    },
    setMonth(teamId) {
      if (this.month) {
        this.quarter = null;
      }
      this.setDate();
      this.getTeamOverview(teamId);
    },
    setDate(date) {
      if (!this.month) {
        if (date) {
          this.startDate = date.start;
          this.endDate = date.end;
        }
      } else {
        this.startDate = new Date(this.month?.year, this.month?.month, 1);
        this.endDate = new Date(this.month?.year, this.month?.month + 1, 0);
      }
    },
  },
  getters: {
    targets() {
      return this.overview.targets;
    },
    getActualTargetAchievedPercentage() {
      return (
        (this.overview.targets?.achieved / this.overview.targets?.actual) * 100
      );
    },
    getCommitedTargetAchievedPercentage() {
      return (
        (this.overview.targets?.achieved / this.overview.targets?.committed) * 100
      );
    },
    averageDailySubmittedJobApplications() {
      return this.overview.jobApplications?.submittedDailyCount?.map(
        (entry) => entry.count
      );
    },
    averageDailySubmittedJobApplicationsCount() {
      const totalCount =
        this.overview.jobApplications?.submittedDailyCount?.reduce(
          (sum, entry) => sum + entry.count,
          0
        );
      return (totalCount / 16).toFixed(2);
    },
    averageDailyApprovedJobApplications() {
      return this.overview.jobApplications?.approvedDailyCount?.map(
        (entry) => entry.count
      );
    },
    averageDailyApprovedJobApplicationsCount() {
      const totalCount =
        this.overview.jobApplications?.approvedDailyCount?.reduce(
          (sum, entry) => sum + entry.count,
          0
        );
      const numElements =
        this.overview.jobApplications?.approvedDailyCount?.length;
      return (numElements > 0 ? totalCount / numElements : 0).toFixed(2);
    },

    //teamData
    getTeamMembersList() {
      const members = this.teamMembers?.map((member) => ({
        id: member.id,
        name: member.name,
      }));
      return [...new Set(members)];
    },
    getClientNameList() {
      const clientName = this.overview.jobApplications?.map(
        (application) => application.clientName
      );
      return [...new Set(clientName)];
    },
    async getSelectedMemberOverview() {
      if (this.selectedMember) {
        const teamMembersList = this.getTeamMembersList;
        const memberId = teamMembersList?.find(
          (member) => member.name === this.selectedMember
        )?.id;
        if (memberId) {
          await this.getUserOverview(memberId);
          return this.overview;
        }
      }
    },

    //job Applications

    jobApplicationStatusCount() {
      const statuses = ["Submitted", "In Progress", "On Hold", "Rejected"];
      const result = [];
      const dataToUse = this.selectedMember
        ? this.overview.jobApplications?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.jobApplications;
      statuses.forEach((status) => {
        let statusData = dataToUse?.filter(
          (application) => application.applicationStatus === status
        ).length;
        result.push(statusData);
      });
      return result;
    },
    jobApplicationCardData() {
      const dataToUse = this.selectedMember
        ? this.overview.jobApplications?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.jobApplications;

      const submittedCount = dataToUse?.length;
      const approvedCount = dataToUse?.filter(
        (application) => application.applicationIsApproved === true
      ).length;
      const updateResumeCount = dataToUse?.filter(
        (application) => application.applicationHasUpdatedResume === false
      ).length;
      const updateAcknowledgeCount = dataToUse?.filter(
        (application) => application.applicationHasAcknowledgement === false
      ).length;

      return [
        { name: "Submitted", count: submittedCount },
        { name: "Approved", count: approvedCount },
        { name: "Resume", count: updateResumeCount },
        { name: "Acknowledgement", count: updateAcknowledgeCount },
      ];
    },

    // placements
    placementsStatus() {
      const statuses = ["Joined", "Pending", "On Hold", "Withdrawn"];
      const result = [];
      const dataToUse = this.selectedMember
        ? this.overview.placements?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.placements;
      statuses.forEach((status) => {
        let statusData = dataToUse?.filter(
          (placement) => placement.status === status
        );
        result.push({
          title: status,
          count: statusData?.length,
          jobApplications: statusData,
        });
      });
      return result;
    },

    //intervews
    interviewTotalCount() {
      const dataToUse = this.selectedMember
        ? this.overview.interviews?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.interviews;
      return dataToUse ? dataToUse.length : 0;
    },
    interviewLevelWiseStatusCount() {
      const levels = ["Level 1", "Level 2", "Level 3"];
      const statuses = [
        "Scheduled",
        "Completed",
        "Passed",
        "Failed",
        "Cancelled",
      ];
      const result = [];
      const dataToUse = this.selectedMember
        ? this.overview.interviews?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.interviews;
      levels.forEach((level) => {
        const levelData = statuses.map((status) => {
          return dataToUse.filter(
            (interview) =>
              interview.level === level && interview.status === status
          ).length;
        });

        result.push({ name: level, data: levelData });
      });
      return result;
    },
    interviewLevel() {
      const dataToUse = this.selectedMember
        ? this.overview.interviews?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.interviews;

      const levels = ["Level 1", "Level 2", "Level 3"];
      const result = [];
      levels?.forEach((level) => {
        let data = dataToUse.filter((data) => data.level === level);

        result.push({
          level: level,
          count: data?.length,
          jobApplications: data,
        });
      });

      return result;
    },

    //offers

    offerStatus() {
      const statuses = ["Pending", "Accepted", "Rejected"];
      const result = [];
      const dataToUse = this.selectedMember
        ? this.overview.offers?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.offers;
      statuses.forEach((status) => {
        const statusData = dataToUse?.filter(
          (offer) => offer.status === status
        );
        result.push({
          title: status,
          count: statusData?.length,
          jobApplications: statusData,
        });
      });
      return result;
    },

    // performance Status

    performanceStatusCounts() {
      const teamMembers = this.teamMembers?.map((member) => member);
      const teamMembersName = [...new Set(teamMembers)];
      const result = [];
      teamMembersName.forEach((member) => {
        //members data
        let jobApplications = this.overview.jobApplications?.filter(
          (application) => application.recruiterName === member.name
        );
        let jobApplicationsInterview = this.overview.interviews?.filter(
          (interview) => interview.recruiterName === member.name
        );
        let percentage =
          member?.recruitmentTargets.actual !== 0
            ? (member?.recruitmentTargets.achieved /
                member?.recruitmentTargets.actual) *
              100
            : 0;

        const submittedJobApplicationsCount = jobApplications?.length;

        const approvedJobApplicationsCount = jobApplications?.filter(
          (application) => application.applicationIsApproved === true
        ).length;
        const dailyAvgJobApplicationsCount =
          member.averageJobApplications.toFixed(2);
        const notServingCount = jobApplications?.filter(
          (application) =>
            application.applicationIsApproved === true &&
            application.candidateNoticePeriodStatus === "Not Serving"
        ).length;

        const notServingPercet =
          (notServingCount / approvedJobApplicationsCount) * 100;

        const screeningFeedbackPendingCount = jobApplications?.filter(
          (application) =>
            application.applicationIsApproved === true &&
            application.applicationHiringStage === "Screening" &&
            application.applicationHiringStatus === "Shared with Client"
        ).length;

        const statusNotUpdatedCount = jobApplications?.filter(
          (application) => application.applicationStatus === "Approved"
        ).length;

        const level1AttendCount = jobApplicationsInterview?.filter(
          (interview) =>
            interview.level === "Level 1" &&
            (interview.status === "Completed" ||
              interview.status === "Passed" ||
              interview.status === "Failed")
        ).length;
        const interviewScheduled = jobApplicationsInterview?.filter(
          (interview) => interview.status === "Scheduled"
        ).length;
        const level1AttendPercent =
          (level1AttendCount / approvedJobApplicationsCount) * 100;

        result.push({
          name: member.name,
          submittedcount: submittedJobApplicationsCount,
          approvedcount: approvedJobApplicationsCount,
          dailyAvgJobApplicationsCount: dailyAvgJobApplicationsCount,
          level1AttendCount: level1AttendCount,
          level1AttendPercent: level1AttendPercent
            ? Math.round(level1AttendPercent)
            : 0,
          interviewScheduled: interviewScheduled,
          notServingCount: notServingCount,
          notServingPercet: notServingPercet ? Math.round(notServingPercet) : 0,
          screeningFeedbackPendingCount: screeningFeedbackPendingCount,
          statusNotUpdatedCount: statusNotUpdatedCount,
          achieved: member?.recruitmentTargets.achieved || 0,
          actual: member?.recruitmentTargets.actual || 0,
          percentage: Math.round(percentage) || 0,
        });
      });
      if (this.selectedMember) {
        return result?.filter((data) => data.name === this.selectedMember);
      } else {
        return result.sort(
          (a, b) =>
            a.dailyAvgJobApplicationsCount - b.dailyAvgJobApplicationsCount
        );
      }
    },
    performanceStatusCountsRecruiter() {
      const submittedJobApplicationsCount =
        this.overview.jobApplications?.length;

      const approvedJobApplicationsCount =
        this.overview.jobApplications?.filter(
          (application) => application.applicationIsApproved === true
        ).length;

      const data = this.getDailyJobApplicationsCount(
        this.overview.jobApplications
      );

      const sumOfJobApplicationsCount = this.sumOfJobApplications(data);
      const dailyAvgJobApplicationsCount = sumOfJobApplicationsCount
        ? (sumOfJobApplicationsCount / data.length).toFixed(2)
        : 0;
      // const dailyAvgJobApplicationsCount = 0;
      const notServingCount = this.overview.jobApplications?.filter(
        (application) =>
          application.applicationIsApproved === true &&
          application.candidateNoticePeriodStatus === "Not Serving"
      ).length;

      const notServingPercet =
        (notServingCount / approvedJobApplicationsCount) * 100;

      const screeningFeedbackPendingCount =
        this.overview.jobApplications?.filter(
          (application) =>
            application.applicationIsApproved === true &&
            application.applicationHiringStage === "Screening" &&
            application.applicationHiringStatus === "Shared with Client"
        ).length;

      const statusNotUpdatedCount = this.overview.jobApplications?.filter(
        (application) => application.applicationStatus === "Approved"
      ).length;

      const level1AttendCount = this.overview.interviews?.filter(
        (interview) =>
          interview.level === "Level 1" &&
          (interview.status === "Completed" ||
            interview.status === "Passed" ||
            interview.status === "Failed")
      ).length;

      const level1AttendPercent =
        (level1AttendCount / approvedJobApplicationsCount) * 100;

      return [
        {
          name: this.usersStore.userDetails?.fullName,
          submittedcount: submittedJobApplicationsCount,
          approvedcount: approvedJobApplicationsCount,
          dailyAvgJobApplicationsCount: dailyAvgJobApplicationsCount,
          level1AttendCount: level1AttendCount,
          level1AttendPercent: level1AttendPercent
            ? Math.round(level1AttendPercent)
            : 0,
          notServingCount: notServingCount,
          notServingPercet: notServingPercet ? Math.round(notServingPercet) : 0,
          screeningFeedbackPendingCount: screeningFeedbackPendingCount,
          statusNotUpdatedCount: statusNotUpdatedCount,
        },
      ];
    },
    target() {
      if (this.selectedMember) {
        const teamMember = this.teamMembers?.find(
          (member) => member.name === this.selectedMember
        );
        let percentage =
          teamMember?.recruitmentTargets.actual !== 0
            ? (teamMember?.recruitmentTargets.achieved /
                teamMember?.recruitmentTargets.actual) *
              100
            : 0;

        return {
          achieved: teamMember?.recruitmentTargets.achieved || 0,
          actual: teamMember?.recruitmentTargets.actual || 0,
          percentage: Math.round(percentage) || 0,
        };
      } else {
        let totalTarget = 0;
        let totalAchieved = 0;
        // let totalPercentage = 0;
        this.teamMembers?.forEach((user) => {
          totalTarget += user?.recruitmentTargets.actual;
          totalAchieved += user?.recruitmentTargets.achieved;
        });
        const overallPercentage =
          totalTarget !== 0 ? (totalAchieved / totalTarget) * 100 : 0;

        return {
          actual: totalTarget || 0,
          achieved: totalAchieved || 0,
          percentage: Math.round(overallPercentage) || 0,
        };
      }
    },
    //Status report
    statusReport() {
      const approvedJobApplications = this.selectedNoticePeriodStatus
        ? this.overview.jobApplications?.filter(
            (application) =>
              application.applicationIsApproved === true &&
              application.candidateNoticePeriodStatus ==
                this.selectedNoticePeriodStatus?.title
          )
        : this.overview.jobApplications?.filter(
            (application) => application.applicationIsApproved === true
          ) ?? [];
      const dataToUse = this.selectedMember
        ? approvedJobApplications?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : approvedJobApplications;

      const screeningFeedbackPendingCount =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Screening" &&
            application.applicationHiringStatus === "Shared with Client"
        ) ?? [];

      const screeningRejectedCount =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Screening" &&
            application.applicationHiringStatus === "Screening Rejected"
        ) ?? [];

      const shortlistedCount =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Screening" &&
            application.applicationHiringStatus === "Shortlisted"
        ) ?? [];

      const statusNotUpdatedCount =
        dataToUse?.filter(
          (application) => application.applicationStatus === "Approved"
        ) ?? [];

      const l1Scheduled =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Level 1" &&
            (application.applicationHiringStatus === "Interview Scheduled" ||
              application.applicationHiringStatus === "Interview Rescheduled")
        ) ?? [];
      const l2Scheduled =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Level 2" &&
            (application.applicationHiringStatus === "Interview Scheduled" ||
              application.applicationHiringStatus === "Interview Rescheduled")
        ) ?? [];
      const l3Scheduled =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Level 3" &&
            (application.applicationHiringStatus === "Interview Scheduled" ||
              application.applicationHiringStatus === "Interview Rescheduled")
        ) ?? [];

      const l1Completed =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Level 1" &&
            (application.applicationHiringStatus === "Interview Completed" ||
              application.applicationHiringStatus === "Interview Passed")
        ) ?? [];
      const l2Completed =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Level 2" &&
            (application.applicationHiringStatus === "Interview Completed" ||
              application.applicationHiringStatus === "Interview Passed")
        ) ?? [];
      const l3Completed =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStage === "Level 3" &&
            (application.applicationHiringStatus === "Interview Completed" ||
              application.applicationHiringStatus === "Interview Passed")
        ) ?? [];

      const selected =
        dataToUse?.filter(
          (application) => application.applicationHiringStatus === "Selected"
        ) ?? [];

      const offered =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStatus === "Offer Accepted" ||
            application.applicationHiringStatus === "Offer Received" ||
            application.applicationHiringStatus === "Placement Initiated"
        ) ?? [];

      const placementCompleted =
        dataToUse?.filter(
          (application) =>
            application.applicationHiringStatus === "Placement Completed"
        ) ?? [];

      const rejectCount =
        dataToUse?.filter(
          (application) =>
            application.applicationStatus === "Rejected" &&
            application.applicationHiringStatus !== "Screening Rejected"
        ) ?? [];

      const holdCount =
        dataToUse?.filter(
          (application) => application.applicationStatus === "On Hold"
        ) ?? [];

      const WithdrawnCount =
        dataToUse?.filter(
          (application) => application.applicationStatus === "Withdrawn"
        ) ?? [];
      return [
        {
          title: "Screening Feedback Pending",
          applications: screeningFeedbackPendingCount,
          count:
            screeningFeedbackPendingCount.length > 0
              ? screeningFeedbackPendingCount.length
              : null,
        },
        {
          title: "Screening Rejected",
          applications: screeningRejectedCount,
          count:
            screeningRejectedCount.length > 0
              ? screeningRejectedCount.length
              : null,
        },
        {
          title: "Shortlisted",
          applications: shortlistedCount,
          count: shortlistedCount.length > 0 ? shortlistedCount.length : null,
        },
        {
          title: "Status Not Updated",
          applications: statusNotUpdatedCount,
          count:
            statusNotUpdatedCount?.length > 0
              ? statusNotUpdatedCount?.length
              : null,
        },
        {
          title: "L1 Scheduled",
          applications: l1Scheduled,
          count: l1Scheduled.length > 0 ? l1Scheduled.length : null,
        },
        {
          title: "L2 Scheduled",
          applications: l2Scheduled,
          count: l2Scheduled.length > 0 ? l2Scheduled.length : null,
        },
        {
          title: "L3 Scheduled",
          applications: l3Scheduled,
          count: l3Scheduled.length > 0 ? l3Scheduled.length : null,
        },
        {
          title: "L1 Completed",
          applications: l1Completed,
          count: l1Completed.length > 0 ? l1Completed.length : null,
        },
        {
          title: "L2 Completed",
          applications: l2Completed,
          count: l2Completed.length > 0 ? l2Completed.length : null,
        },
        {
          title: "L3 Completed",
          applications: l3Completed,
          count: l3Completed.length > 0 ? l3Completed.length : null,
        },
        {
          title: "Selected",
          applications: selected,
          count: selected.length > 0 ? selected.length : null,
        },

        {
          title: "Offered",
          applications: offered,
          count: offered.length > 0 ? offered.length : null,
        },
        {
          title: "Placement",
          applications: placementCompleted,
          count:
            placementCompleted.length > 0 ? placementCompleted.length : null,
        },
        {
          title: "Rejected",
          applications: rejectCount,
          count: rejectCount.length > 0 ? rejectCount.length : null,
        },
        {
          title: "Hold",
          applications: holdCount,
          count: holdCount.length > 0 ? holdCount.length : null,
        },
        {
          title: "Withdrawn",
          applications: WithdrawnCount,
          count: WithdrawnCount.length > 0 ? WithdrawnCount.length : null,
        },
      ];
    },
    noticePeriodStatusCounts() {
      const statuses = ["Immediate", "Serving", "Not Serving"];
      const dataToUse = this.selectedMember
        ? this.overview.jobApplications?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.jobApplications;

      const result = [];
      if (this.selectedStatus) {
        statuses.forEach((status) => {
          let statusData = this.selectedStatus.applications?.filter(
            (application) => application.candidateNoticePeriodStatus === status
          ).length;
          result.push({ title: status, count: statusData });
        });
      } else {
        statuses.forEach((status) => {
          let statusData = dataToUse?.filter(
            (application) =>
              application.applicationIsApproved === true &&
              application.candidateNoticePeriodStatus === status
          ).length;
          result.push({ title: status, count: statusData });
        });
      }

      return result;
    },
    cvSharedToClient() {
      const clientName = this.overview.jobApplications
        ?.filter((application) => application.applicationIsApproved === true)
        ?.map((application) => application.clientName);

      const uniqueClientName = [...new Set(clientName)];
      const result = [];

      const dataToUse = this.selectedMember
        ? this.overview.jobApplications?.filter(
            (application) => application.recruiterName === this.selectedMember
          )
        : this.overview.jobApplications;

      if (this.selectedStatus && this.selectedNoticePeriodStatus) {
        uniqueClientName.forEach((client) => {
          let jobApplications = this.selectedStatus.applications?.filter(
            (application) =>
              application.clientName === client &&
              application.candidateNoticePeriodStatus ===
                this.selectedNoticePeriodStatus?.title
          );
          result.push({
            clientName: client,
            count: jobApplications.length > 0 ? jobApplications.length : null,
            jobApplications: jobApplications,
          });
        });
      } else if (this.selectedStatus) {
        const statusData = this.statusReport?.find(
          (status) => status.title === this.selectedStatus.title
        );
        uniqueClientName.forEach((client) => {
          let jobApplications = statusData?.applications?.filter(
            (application) => application.clientName === client
          );
          result.push({
            clientName: client,
            count: jobApplications.length > 0 ? jobApplications.length : null,
            jobApplications: jobApplications,
          });
        });
      } else if (this.selectedNoticePeriodStatus) {
        uniqueClientName.forEach((client) => {
          let jobApplications = dataToUse
            ?.filter(
              (application) =>
                application.applicationIsApproved === true &&
                application.candidateNoticePeriodStatus ===
                  this.selectedNoticePeriodStatus?.title
            )
            ?.filter((application) => application.clientName === client);
          result.push({
            clientName: client,
            count: jobApplications.length > 0 ? jobApplications.length : null,
            jobApplications: jobApplications,
          });
        });
      } else {
        uniqueClientName.forEach((client) => {
          let jobApplications = dataToUse
            ?.filter(
              (application) => application.applicationIsApproved === true
            )
            ?.filter((application) => application.clientName === client);
          result.push({
            clientName: client,
            count: jobApplications.length > 0 ? jobApplications.length : null,
            jobApplications: jobApplications,
          });
        });
      }

      return result;
    },

    FileName() {
      if (this.selectedMember) {
        return `${this.selectedMember.split(" ").join("_")}_${
          months[this.month.month]
        }-${this.month.year}_jobApplications`;
      } else {
        return `Team_${this.teamStore.team.name}_${months[this.month.month]}-${
          this.month.year
        }_jobApplications`;
      }
    },
  },
});
